import React, { Component } from 'react';
import { Modal, Button, View } from 'react-native';
import styled from 'styled-components/native';
import snip from 'styles/snip.js';
import colors from 'styles/colors';

const ModalOverlay = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContainer = styled(View)`
  width: 80%;
  background-color: #e6e6e6;
  padding: 10px;
  border-radius: 4px;
`;


const ButtonContainer = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`;

const ButtonWrap = styled(View)`
	flex: 0.48 0.48 12%;
`;

class RejectNoteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      body: props.body || '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.body !== this.props.body) {
      this.setState({ body: this.props.body });
    }
  }

  handleClose = (saveAndReject) => {
    const { body } = this.state;
    const { onRequestClose } = this.props;
    onRequestClose({ rejectNote: body, saveAndReject });
  };

  render() {
    const { visible } = this.props;
    const { body } = this.state;

    return (
      <Modal
        transparent={true}
        visible={visible}
        onRequestClose={() => { this.handleClose(false); }}
      >
        <ModalOverlay>
          <ModalContainer>
            <textarea
                placeholder="Provide revision instructions"
                style={{ ...snip.bdrAll, minHeight: '7em', marginBottom: snip.pad, padding: '0.5em', resize: 'none' }}
                value={body}
                onChange={(evt) => {
                    this.setState({ body: evt.target.value })
                    evt.persist();
                }}
            />
            <ButtonContainer>
              <ButtonWrap>
                <Button title="Cancel" onPress={() => { this.handleClose(false); }} color={`${colors.red}`} />
              </ButtonWrap>
              <ButtonWrap>
                <Button title="Continue" onPress={() => { this.handleClose(true); }} color={`${colors.evp_blue}`}/>
              </ButtonWrap>
            </ButtonContainer>
          </ModalContainer>
        </ModalOverlay>
      </Modal>
    );
  }
}

export default RejectNoteModal;
