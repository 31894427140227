import axios from 'axios';
import LS    from 'utils/localStore.js';
import OS    from 'utils/offlineStore.js';
import { dynamicSort, isOnline } from 'utils';
import { showAlert }   from 'actions/alert';
import { FETCH_ATTESTATIONS_SUCCESS, SUBMIT_ATTESTATION_SUCCESS } from 'constants.js';
import LDSH   from 'lodash';

export function getCachedAttest(dispatch, userId) {

	return LS.All('attestations')
		.then((arAttest) => {

			const attestations = Array.isArray(arAttest) ? arAttest : [];
			attestations.sort(dynamicSort('due_at', 'asc'));
			dispatch({ type: FETCH_ATTESTATIONS_SUCCESS, attestations, userId });
			return Promise.resolve({ data: attestations });
		});
}

export function fetchAttestations(userId, bForceAjax = false) {

	return (dispatch) => {
		if(isOnline()) {
			// SAVE ANY DIRTY BEFORE REPLACING THE OLD ATTESTATION/IMAGE CACHE
			return axios.get(`/users/${userId}/attestations?data_for_offline=true`)
				.then((rsp) => {

					OS.Store('attestations').clear()
					.then((cleared) => OS.BulkPut('attestations', rsp.data));

					// CLEAR & RE-INPUT ATTESTATIONS INTO CACHE
					return LS.Store('attestations').clear()
						.then((cleared) => LS.BulkPut('attestations', rsp.data))
						.then((lastId) => {
							dispatch({ type: FETCH_ATTESTATIONS_SUCCESS, attestations: rsp.data, userId:userId });

							return Promise.resolve(rsp);
						});
				})
				.catch((err) => {

					if( bForceAjax === true )
						return Promise.reject(err);
					else
						return getCachedAttest(dispatch, userId)
				});
		} else {
			return OS.All('attestations')
			.then((rsp) => {
				rsp.sort(dynamicSort('due_at', 'asc'));
				return LS.Store('attestations').clear()
				.then((cleared) => LS.BulkPut('attestations', rsp))
				.then((lastId) => {
					dispatch({ type: FETCH_ATTESTATIONS_SUCCESS, attestations: rsp, userId:userId });
					return Promise.resolve({ data:rsp });
				});
			})
		}
	};
}

export function fetchAttestation(userId, id) {

	userId = parseInt(userId);
	id = parseInt(id);

	return (dispatch) => {

		return LS.Store('attestations')
			.filter((V) => ((V.id === id) && ((V.inspector_id === userId) || (V.attester_id === userId))))
			.toArray((ret) => {

				if( Array.isArray(ret) && (ret.length > 0) )
					return Promise.resolve(ret[0]);

				showAlert(
					'Press OK to continue.',
					'Attestation Not Found',
					'err'
				)(dispatch);

				// TODO: in UI, go back to list when attestations not found
				return Promise.reject({ message: 'attestations not found' });
			});
	};
}

export function checkImages(userId, attestationId) {
	return (dispatch) => {
		return axios.get(`/users/${userId}/attestations/${attestationId}/check_images`)
		.then((rsp) => {
			return Promise.resolve(rsp);
		});
	}
}

export function submitAttestation(userId, attestationId, isAttesterSubmitting, inspection_id) {

	userId       = parseInt(userId);
	attestationId = parseInt(attestationId);
	let apiEndpoint = isAttesterSubmitting ? 'attest' : 'submit';

	if(isOnline()) {
		return (dispatch) => {
			let signaturePayload = {
				isSubmittedOffline:false, 
				...(isAttesterSubmitting 
					? { isAttesterSignChecked: true }
					: { isInspectorSignChecked: true }
				),
			}
			return axios.patch(`/users/${userId}/attestation/${attestationId}/${apiEndpoint}`,  signaturePayload)
				.then((rsp) => {

					return LS.Get('attestations', attestationId)
						.then((oldInsp) => {

							// OVERWRITE CACHED ATTESTATION WITH RETURNED DATA,
							// PRESERVE CACHED IMAGE DATA
							const newInsp = oldInsp ? Object.assign({}, oldInsp, rsp.data, { images: oldInsp.images }) : rsp.data;
							return LS.Put('attestations', newInsp).then(() => Promise.resolve(newInsp));
						});
				})
				.then((newInsp) => {

					dispatch({ type: SUBMIT_ATTESTATION_SUCCESS, attestation: newInsp });

					showAlert(
						'Press OK to continue.',
						'Attestation Submitted',
						'ok'
					)(dispatch);

					return Promise.resolve(newInsp);
				});
		};
	} else {
		return (dispatch) => {
			let signaturePayload = {
				isSubmittedOffline:true, 
				...(isAttesterSubmitting 
					? { isAttesterSignChecked: true }
					: { isInspectorSignChecked: true }
				),
			}
            var obj = {
				id: Date.now(),
				api: 'submitAttestation',
				url: `/users/${userId}/attestation/${attestationId}/${apiEndpoint}`,
				signaturePayload:signaturePayload,
				data: { attestations: { id: attestationId}, inspection:{id:inspection_id} }
			}
			OS.Put('pendingAPI', obj);
			return LS.Get('attestations', attestationId)
			.then((oldInsp) => {
				oldInsp.status = 'review';
				
				OS.Put('attestations', { ...oldInsp });
				
				return LS.Put('attestations', oldInsp).then(() => Promise.resolve(oldInsp));
			})
			.then((newInsp) => {
				dispatch({ type: SUBMIT_ATTESTATION_SUCCESS, attestation: newInsp });

				showAlert(
					'Press OK to continue.',
					'Attestation Submitted',
					'ok'
				)(dispatch);

				return Promise.resolve(newInsp);
			});
        }
	}
}

export function rejectAttestation(userId, attestationId, rejectNote){
	// /users/{user_id:[0-9]+}/attestation/{attest_id:[0-9]+}/{action:(?:accept|attest|reject|submit)}
	userId       = parseInt(userId);
	attestationId = parseInt(attestationId);

	if(isOnline()) {
		return (dispatch) => {
			return axios.patch(`/users/${userId}/attestation/${attestationId}/reject`,  {isSubmittedOffline:false, instructions:rejectNote})
				.then((rsp) => {

					return LS.Get('attestations', attestationId)
						.then((oldInsp) => {

							// OVERWRITE CACHED ATTESTATION WITH RETURNED DATA,
							// PRESERVE CACHED IMAGE DATA
							const newInsp = oldInsp ? Object.assign({}, oldInsp, rsp.data, { images: oldInsp.images }) : rsp.data;
							return LS.Put('attestations', newInsp).then(() => Promise.resolve(newInsp));
						});
				})
				.then((newInsp) => {

					dispatch({ type: SUBMIT_ATTESTATION_SUCCESS, attestation: newInsp });

					showAlert(
						'Press OK to continue.',
						'Attestation Rejected',
						'ok'
					)(dispatch);

					return Promise.resolve(newInsp);
				});
		};
	} else {
		return (dispatch) => {
            var obj = {
				id: Date.now(),
				api: 'rejectAttestation',
				url: `/users/${userId}/attestation/${attestationId}/reject`,
				data: { attestations: { id: attestationId}, instructions:rejectNote }
			}
			OS.Put('pendingAPI', obj);
			return LS.Get('attestations', attestationId)
			.then((oldInsp) => {
				oldInsp.status = 'pending';
				
				OS.Put('attestations', { ...oldInsp });
				
				return LS.Put('attestations', oldInsp).then(() => Promise.resolve(oldInsp));
			})
			.then((newInsp) => {
				dispatch({ type: SUBMIT_ATTESTATION_SUCCESS, attestation: newInsp });

				showAlert(
					'Press OK to continue.',
					'Attestation Rejected',
					'ok'
				)(dispatch);

				return Promise.resolve(newInsp);
			});
        }
	}
}

function saveImageLocal(params) {

	const { filename, user, attestation, blob, type, key, latitude, longitude, offline } = params;
	const now   = new Date();
	const szNow = now.toISOString();

	return new Promise((fnOK, fnERR) => {

		let rdr = new FileReader();
		rdr.onload = (evt) => {

			let tmpId = new Uint8Array(8);
			window.crypto.getRandomValues(tmpId);

			// CREATE TEMPORARY IMAGE RECORD
			const imgNew = {
				// id:              tmpId.join('.'),
				id:              Date.now(),
				user_id:         user.id,
				attestation_id:   attestation.id,
				organization_id: attestation.organization_id,
				user,
				type,
				key,
				latitude,
				longitude,
				file:          filename,
				comments:      [],
				inserted_at:   szNow,
				updated_at:    szNow,
				notes:         null,
				square:        evt.target.result,
				thumbnail:     evt.target.result,
				is_temp_image: true,
			};

			// ADD TO ATTESTATION CACHE
			LS.Get('attestations', attestation.id)
				.then((attest) => {

					if( !attest ) {
						fnERR({ message: 'saveImageLocal: attestations not found' });
						return;
					}

					if( Array.isArray(attest.images) )
						attest.images.push(imgNew);
					else
						attest.images = [imgNew];
					if(offline) {
						LS.Put('attestations', { ...attest })
							.then(() => fnOK(imgNew));
					} else {
						LS.Put('attestations', { ...attest, is_dirty: true })
							.then(() => fnOK(imgNew));
					}
				})
				.catch((err) => fnERR(err));
		};
		rdr.readAsDataURL(blob);

	});
}

function dataURItoBlob(dataURI) {

	const ixComma    = dataURI.indexOf(',');
	const prefix     = dataURI.substring(0, ixComma);
  const byteString = atob(dataURI.substring(ixComma+1));

	// separate out the mime component
	const mimeString = prefix.split(':')[1].split(';')[0]

	// write the bytes of the string to an ArrayBuffer
	let ab = new ArrayBuffer(byteString.length);

	// create a view into the buffer
	let ia = new Uint8Array(ab);

	// set the bytes of the buffer to the correct values
	for(let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	// write the ArrayBuffer to a blob, and you're done
	return new Blob([ab], {type: mimeString});
}

function uploadImageBase(user, attestation, blob, type, key, latitude, longitude, tempId = null, imageId = null) {

	const inspection_id = parseInt(attestation.inspection.id);
	const attestation_id = parseInt(attestation.id);
	const user_id       = parseInt(user.id);
	const filename      = `${type}_${key}.jpg`;

	const data = new FormData();

	if( typeof blob === 'string' )
		blob = dataURItoBlob(blob);

	data.append('image[file]', blob, filename);
	data.append('image[type]', type);
	data.append('image[key]', key);
	data.append('image[latitude]', latitude);
	data.append('image[longitude]', longitude);
	data.append('image[image_id]', imageId);

	return axios.post(`/users/${user_id}/inspections/${inspection_id}/images`, data)
		.then((rsp) => {

			// ADD TO ATTESTATION CACHE
			return LS.Get('attestations', attestation_id)
				.then((attest) => {

					if( !attest )
						return Promise.reject({ message: 'uploadImage: inspection not found' });

					if( Array.isArray(attest.images) ) {

						// REMOVE CORRESPONDING TEMP IMAGE, IF SPECIFIED
						if( tempId !== null )
							attest.images = attest.images.filter((I) => (I.id !== tempId));

						attest.images.push(rsp.data);
					}
					else
						attest.images = [rsp.data];

					return OS.Put('attestations', attest)
					.then(() => {
						return LS.Put('attestations', attest).then(() => Promise.resolve(rsp));
					});
				});
		})
		.catch((err) => {

			console.error('SAVE LOCAL', err);
			throw err;
		});
}


export function uploadPendingImageAttestation(user, attestations, blob, type, key, latitude, longitude, tempId = null, imageId = null) {
	return uploadImageBase(user, attestations, blob, type, key, latitude, longitude, tempId, imageId);
}

export function uploadImage(user, attestation, blob, type, key, latitude, longitude, tempId = null) {

		return (dispatch) => {
			var obj = {
				id: Date.now(),
				api: 'uploadAttestationImage',
				data: {
					user,
					attestation,
					blob,
					type,
					key,
					latitude,
					longitude,
					tempId
				}
			}
	
			var filename = obj.id + '.jpg';
			return saveImageLocal(
				{ filename, user, attestation, blob, type, key, latitude, longitude, offline: true }
			)
			.then((imgNew) => {
				obj.id = imgNew.id;
				obj.data.tempId = imgNew.id;
				OS.Get('attestations', attestation.id)
				.then((attest) => {
					let rdr = new FileReader();
					rdr.onload = (evt) => {
						if( Array.isArray(attest.images) )
							attest.images.push(imgNew);
						else
							attest.images = [imgNew];
						
						OS.Put('attestations', { ...attest })
					}
					rdr.readAsDataURL(blob);
				})
				OS.Put('pendingAPI', obj);
				return Promise.resolve({ data: { ...imgNew } });
			});
		};
	// }
}

export function deleteComment(userId, attestationId, imageId, commentId){

	attestationId = parseInt(attestationId);

	return (dispatch) => {

		return LS.GetAttestationImage(attestationId, imageId)
			.then((img) => {
					if(isOnline()) {
						return axios.delete(`/users/${userId}/images/${imageId}/comment/${commentId}`)
					} else {
						OS.All('pendingAPI')
							.then((pendingAPIArray)=>{
								let createCommentObj = pendingAPIArray.find(api => api.id === commentId);
								if(createCommentObj){
									OS.Delete('pendingAPI', createCommentObj.id);
								}
								else {
									var obj = {
										id: Date.now(),
										api: 'deleteComment',
										url: `/users/${userId}/images/${imageId}/comment/${commentId}`,
									}
									OS.Put('pendingAPI', obj);
								}

						})
					}
			})
			.then((rsp) => {

				// REMOVE FROM ATTESTATION CACHE
				return LS.Get('attestations', attestationId)
					.then((attest) => {

						if( !attest )
							return Promise.reject({ message: 'deleteComment: attestations not found' });
						
						// DELETE COMMENT
						if( Array.isArray(attest.images) ){
							const imageForComment = LDSH.filter(attest.images, { id: imageId });							
							imageForComment[0].comments = imageForComment[0].comments.filter((I) => ( I.id !== commentId ));
						}

						OS.Put('attestations', { ...attest });

						return LS.Put('attestations', attest).then(() => Promise.resolve(rsp));
					});
			})
			.catch(err => {return Promise.reject(err)});
	};


}

export function deleteImage(userId, attestationId, imageId, inspectionId) {

	attestationId = parseInt(attestationId);

	return (dispatch) => {

		return LS.GetAttestationImage(attestationId, imageId)
			.then((img) => {

				// SKIP AJAX ON TEMP IMAGES
				if( img && (img.is_temp_image === true) ) {
					OS.Delete('pendingAPI', imageId);
					return Promise.resolve({ data: img });
				}
				else {
					if(isOnline()) {
						return axios.delete(`/users/${userId}/inspections/${inspectionId}/images/${imageId}`);
					} else {
						var obj = {
							id: Date.now(),
							api: 'deleteImage',
							url: `/users/${userId}/inspections/${inspectionId}/images/${imageId}`,
						}
						OS.Put('pendingAPI', obj);
						return Promise.resolve({ data: img });
					}
				}
			})
			.then((rsp) => {

				// REMOVE FROM ATTESTATION CACHE
				return LS.Get('attestations', attestationId)
					.then((attest) => {

						if( !attest )
							return Promise.reject({ message: 'deleteImage: attestations not found' });

						// DELETE IMAGE
						if( Array.isArray(attest.images) )
							attest.images = attest.images.filter((I) => ( I.id !== imageId ));
						else
							attest.images = [];

						OS.Put('attestations', { ...attest });

						return LS.Put('attestations', attest).then(() => Promise.resolve(rsp));
					});
			});
	};
}

export function updateComment(attestationId, inspectionId, comment){

	attestationId = parseInt(attestationId);
	inspectionId = parseInt(inspectionId);
	let imageId = comment.assoc_id;

	return (dispatch) => {

		return LS.GetAttestationImage(attestationId, imageId)
			.then((img) => {

					if(isOnline()) {
						return axios.patch(`/inspections/${inspectionId}/images/${comment.assoc_id}/comments/${comment.id}`, { comment })
					} else {
					OS.All('pendingAPI')
						.then((pendingAPIArray)=>{
							let createCommentObj = pendingAPIArray.find(api => api.id === comment.id);
							if(createCommentObj){
								createCommentObj.data.comment.body = comment.body;
								OS.Delete('pendingAPI', createCommentObj.id);
								OS.Put('pendingAPI', createCommentObj);
							}
							else {
								var updateObj = {
									id: Date.now(),
									api: 'updateComment',
									url: `/inspections/${inspectionId}/images/${comment.assoc_id}/comments/${comment.id}`,
									data: { comment },
								}
								OS.Put('pendingAPI', updateObj);
							}

						})
						return Promise.resolve({ data: img });
					}
			})
			.then((rsp) => {

				// UPDATE FROM ATTESTATION CACHE
				return LS.Get('attestations', attestationId)
					.then((attest) => {

						if( !attest )
							return Promise.reject({ message: 'deleteComment: attestations not found' });
						
						// UPDATE COMMENT
						if( Array.isArray(attest.images) ){
							const imageForComment = LDSH.filter(attest.images, { id: imageId });
							const index = imageForComment[0].comments.findIndex(obj => obj.id === comment.id);
							if (index !== -1) {
								imageForComment[0].comments[index] = comment;
							}
						}

						OS.Put('attestations', { ...attest });

						return LS.Put('attestations', attest).then(() => Promise.resolve(rsp));
					});
			});
	};
}

export function createComment(attestationId, inspectionId, imageId, data) {

	inspectionId = parseInt(inspectionId);
	attestationId = parseInt(attestationId);

	return (dispatch) => {
		return LS.GetAttestationImage(attestationId, imageId)
		.then((_img) => {

			if(isOnline() && (_img && !_img.is_temp_image)) {
				return axios.post(`/inspections/${inspectionId}/images/${imageId}/comments`, { comment: data })
				.then((rsp) => {
	
					// ADD TO ATTESTATION CACHE
					return LS.Get('attestations', attestationId)
						.then((attest) => {
	
							if( !attest )
								return Promise.reject({ message: 'createComment: attestations not found' });
	
							let img;
							if( Array.isArray(attest.images) )
								img = attest.images.find((IMG) => (IMG.id === imageId));
	
							if( !img )
								return Promise.reject({ message: 'createComment: image not found' });
	
							if( Array.isArray(img.comments) )
								img.comments.push(rsp.data);
							else
								img.comments = [rsp.data];
	
							return LS.Put('attestations', attest).then(() => Promise.resolve(rsp));
						});
				});
			} else {
				var newImageId;
				var oldNewImageId = sessionStorage.getItem('OldNewImageId');
				if(oldNewImageId) {
					oldNewImageId = JSON.parse(oldNewImageId);
					if(oldNewImageId.old === imageId) {
						newImageId = oldNewImageId.new;
						imageId = newImageId;
					}
				}
				var obj = {
					id: Date.now(),
					api: 'addComment',
					url: `/inspections/${inspectionId}/images/${imageId}/comments`,
					data: { comment: data },
					tempImgId: null,
				}
				OS.Put('pendingAPI', obj);
	
				// ADD TO ATTESTATION CACHE
				return OS.Get('attestations', attestationId)
				.then((attest) => {
	
					if( !attest )
						return Promise.reject({ message: 'createComment: attestations not found' });
	
					let img;
					if( Array.isArray(attest.images) )
						img = attest.images.find((IMG) => (IMG.id === imageId));
	
					if(!newImageId) {
						if( !img )
							return Promise.reject({ message: 'createComment: image not found' });
		
						if(img.is_temp_image) {
							obj.tempImgId = img.id;
							OS.Put('pendingAPI', obj);
						}
					}
	
					return LS.Get('auth', 1)
					.then((authRet) => {
						return authRet.data;
					})
					.then((user) => {
						return {
							data: {
								id: obj.id,
								assoc_id: imageId,
								user_id: user.id,
								...data,
								user: user,
								inserted_at: new Date(),
								updated_at: new Date(),
							}
						}
					})
					.then((rsp) => {
						if(newImageId) {
							return Promise.resolve(rsp);
						} else {
							if( Array.isArray(img.comments) )
								img.comments.push(rsp.data);
							else
								img.comments = [rsp.data];
							LS.Put('attestations', attest);
							return OS.Put('attestations', attest).then(() => Promise.resolve(rsp));
						}
					})
					.catch(err => Promise.reject(err));
				});
			}
		});
	};
}

export function uploadAttestationDocument(attestationId, userId, documentBlob, filename) {

	attestationId = parseInt(attestationId);
	userId = parseInt(userId);

	const updateLocalCacheForNewFileUpload = (attestationId, filename, documentId, is_temp_doc, user_id) =>{
		return LS.Get('attestations', attestationId)
		.then((attest) => {

			if( !attest )
				return Promise.reject({ message: 'uploadDocument: attestations not found' });

			if( Array.isArray(attest.documents) )
				attest.documents.push({file:filename, id:documentId, is_temp:is_temp_doc, user_id:user_id, attestation_id:attestationId});
			else
				attest.documents = [{file:filename, id: documentId, is_temp:is_temp_doc, user_id:user_id, attestation_id:attestationId}];

			return LS.Put('attestations', attest).then(() => Promise.resolve(attest));
		})
	}

	return (dispatch) => {
		if(isOnline()){
	
			const formDataPayload = new FormData();

			formDataPayload.append('document', documentBlob, filename);

			return axios.post(`/users/${userId}/attestation/${attestationId}/document`, formDataPayload)
			.then((response)=>{
				return updateLocalCacheForNewFileUpload(attestationId, response.file, response.id, false, userId)
			})
		}
		else{
			var obj = {
				id: Date.now(),
				api: 'attestationDocumentUpload',
				url: `/users/${userId}/attestation/${attestationId}/document`,
				data: { 
					filename: filename,
					attestationId:attestationId,
					documentBlob: documentBlob,
				 }
			}
			OS.Put('pendingAPI', obj);
			return updateLocalCacheForNewFileUpload(attestationId, filename, obj.id, true, userId);
		}
	};
}

export function deleteAttestationDocument(attestationId, userId, docDetails) {

	attestationId = parseInt(attestationId);
	userId = parseInt(userId);
	const { document_id, is_temp_doc} = docDetails;

	const updateLocalCacheForFileDelete = (attestationId,docId) =>{
		return LS.Get('attestations', attestationId)
		.then((attest) => {

			if( !attest )
				return Promise.reject({ message: 'uploadDocument: attestations not found' });

			if( Array.isArray(attest.documents) ){
				attest.documents = attest.documents.filter((doc)=> doc.id !== docId)
			}

			return LS.Put('attestations', attest).then(() => Promise.resolve(attest));
		})
	}

	return (dispatch) => {
		if(isOnline()){
			return axios.delete(`/users/${userId}/attestation/${attestationId}/document/${document_id}`)
			.then(()=>{
				return updateLocalCacheForFileDelete(attestationId,document_id)
			})
		}
		else{
			var obj = {
				id: Date.now(),
				api: 'attestationDocumentDelete',
				url: `/users/${userId}/attestation/${attestationId}/document/${document_id}`,
				data: {
					attestationId:attestationId,
					is_temp: is_temp_doc,
				 }
			}
			OS.Put('pendingAPI', obj);
			return updateLocalCacheForFileDelete(attestationId,document_id);
		}
	};
}

export function downloadDocumentFile(user_id, attestation_id, document_id){

	return (dispatch) =>{
		if(isOnline()){
			return axios.get(`/users/${user_id}/attestation/${attestation_id}/document/${document_id}`,
				{
					headers: { Accept: 'application/pdf' },
					responseType: 'blob',
				}
			)
		}
		else{
			return Promise.reject({error: "Please check the internet connection and try again later."})
		}
	}
}