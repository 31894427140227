import React from 'react';
import { View, Text } from 'react-native';
import * as IO5 from 'react-icons/io5';

class Waiting extends React.Component {

  render() {

		const { style = null, waitMsg = '' } = this.props;

		const cssMsg = {
			width:           '90%',
			color:           'black',
			backgroundColor: 'white',
			border:          '1px solid #ccc',
			padding:         '0.5em',
			fontSize:        '12pt',
			textAlign:       'center',
			borderRadius:    '3px',
			boxShadow:       '3px 3px 4px #aaa',
		};

		const cssDefault = {
			position: 'fixed',
			zIndex:   '1000',
			width:    '100%',
			top:      '0px',
			bottom:   '0px',

			display:        'flex',
			justifyContent: 'center',
			alignItems:     'center',

			color: 'white',
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
		};

		const cssContainer = (style === null) ? cssDefault : { ...cssDefault, ...style };
		const cssText = { color: 'inherit', animation: 'spin 4s linear infinite' };
		const bHasMsg = ( (typeof waitMsg === 'string') && (waitMsg.length > 0) );

		return (
			<View style={cssContainer}>
				{ bHasMsg && <Text style={cssMsg}>{waitMsg}</Text> }
				{ !bHasMsg && <View style={cssText}><IO5.IoSync size="200px"/></View> }
			</View>
		);
  }
}

export default Waiting;

