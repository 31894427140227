// @flow
import React, { Component } from 'react';
import { View, Text, Button } from 'react-native';
import styled from 'styled-components/native';
import snip   from 'styles/snip.js';
import colors from 'styles/colors.js';

const Shadow = styled(View)`
	z-index:  200;
	position: fixed;
	top:      0px;
	bottom:   0px;
	width:    100%;
	padding:  1rem;

	display:  flex;
	flex-direction:  column;
	justify-content: center;
	align-items:     stretch;

	margin:           0px;
	background-color: rgba(0, 0, 0, 0.5);
`;

const AlertView = styled(View)`
	flex-direction:  column;
	justify-content: space-between;

	border:           ${props => snip.bdr};
	box-shadow:       ${props => snip.boxShad};
	border-radius:    3px;
	background-color: white;
`;

const Title = styled(View)`
	text-align: center;
	padding:    ${props => snip.pad};
	color:      white;
`;

const TextCommon = styled(Text)`
	padding: 0px;
	border-style: none;
`;

const Body = styled(View)`
	padding:    1em;
	flex:       1 1 auto;
	max-height: 50vh;
	overflow:   auto;
`;

type Props = {
	title:     string,
	message:   string,
	mode:      string,
	hideAlert: Function,
};

class Alert extends Component {

	props: Props;

	render() {

		const { title, message, mode, hideAlert } = this.props;
		const color = (mode === 'ok') ? colors.green_primary : colors.red;

		let msgDisplay = '';

		if( typeof message === 'string' ) {
			msgDisplay = message;
		}
		else if( typeof message === 'object' ) {

			if( message.hasOwnProperty('error') )
				msgDisplay = message.error;
			else if( message.hasOwnProperty('message') )
				msgDisplay = message.message;
			else
				msgDisplay = JSON.stringify(message, null, '  ');
		}

		return (
			<Shadow>
				<AlertView>
					<Title style={{backgroundColor: color}}>
						<TextCommon style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'inherit' }}>{title}</TextCommon>
					</Title>
					<Body>
						<TextCommon style={{ fontSize: '1rem', fontFamily: 'monospace' }}>{msgDisplay}</TextCommon>
					</Body>
					<View style={{ paddingHorizontal: '35%', paddingVertical: snip.pad }}>
						<Button
							color={color}
							onPress={(evt) => { hideAlert(); }}
							title="OK"
						/>
					</View>
				</AlertView>
			</Shadow>
		);
	}
}

export default Alert;

