import filter from 'lodash/filter';
import { removeFromArray } from 'utils';
import {
  FETCH_INSPECTIONS_SUCCESS,
  SUBMIT_INSPECTION_SUCCESS,
	SET_HISTORY_TAB_IX,
  SET_HISTORY_SECTION_INDEX,
  SET_USER_VISITED_HISTORY,
} from 'constants.js';

const initialState = {
  pending: [],
  history: [],
	historyTabIndex: 0,
  historySectionIndex:0,
  userVisitedHistory:false,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
		case SET_HISTORY_TAB_IX:
			return {
				...state,
				historyTabIndex: action.index,
			};
    case SET_HISTORY_SECTION_INDEX:
        return {
          ...state,
          historySectionIndex: action.index,
        };
    case SET_USER_VISITED_HISTORY:
      return{
        ...state,
        userVisitedHistory: action.usingHistoryTab
      };
    case FETCH_INSPECTIONS_SUCCESS:
      localStorage.setItem('isInspectionsLoaded', 'true');
      localStorage.setItem('reloadInspection', 'true');
      return {
        ...state,
        pending: filter(action.inspections, (i) => i.status === 'pending'),
        history: filter(action.inspections, (i) => i.status !== 'pending'),
      };
    case SUBMIT_INSPECTION_SUCCESS: {
      const index = state.pending.map((i) => i.id).indexOf(action.inspection.id);
      return {
        ...state,
        pending: removeFromArray(state.pending, index),
        history: [...state.history, action.inspection],
      };
    }
    default:
      return state;
  }
};

