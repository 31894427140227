// @flow
import React, { Component } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import snip   from 'styles/snip.js';
import Input  from 'components/Input';

const Shadow = styled(View)`
	z-index:  200;
	position: fixed;
	top:      0px;
	bottom:   0px;
	width:    100%;
	padding:  1rem;

	display:  flex;
	flex-direction:  column;
	justify-content: center;
	align-items:     stretch;

	margin:           0px;
	background-color: rgba(0, 0, 0, 0.5);
`;

const AlertView = styled(View)`
	flex-direction:  column;
	justify-content: space-between;
	text-align: center;
	border:           ${props => snip.bdr};
	border-radius:    10px;
	background-color: white;
	width: fit-content;
	margin: auto;
`;

const BtnTxt = styled(Text)`
	padding:   0.7rem;
	font-size: inherit;
	color:     rgb(0, 122, 255);
`;

type Props = {
	onAccept: Function,
	onReject: Function,
	onChange: Function,
	visible:  Boolean,
	label:    String,
};

class LocationAlert extends Component {
	props: Props;

	onAccept = () => {
		if(this.props.label.replace(/^\s+|\s+$/gm,'')) {
			this.props.onAccept();
		} else {
			alert('Image Description is required');
		}
	};

	render() {

		const { onReject, onChange, visible, label } = this.props;

		return (
			<React.Fragment>
				{visible &&
					<Shadow>
						<AlertView>
							<View>
								<Input
									label={"Image Description"}
									value={label}
									onChangeText={(value) => onChange(value)}
									style={{ 
										borderBottom: snip.bdr,
										borderTopLeftRadius: '10px', 
										borderTopRightRadius: '10px'
									}}
								/>
								
								<TouchableOpacity
									onPress={() => this.onAccept()}
									style={{ borderBottom: snip.bdr }}
								>
									<BtnTxt>{'OK'}</BtnTxt>
								</TouchableOpacity>
								<TouchableOpacity
									onPress={() => onReject()}
								>
									<BtnTxt>{'CANCEL'}</BtnTxt>
								</TouchableOpacity>
							</View>
						</AlertView>
					</Shadow>
				}
			</React.Fragment>
		);
	}
}

export default LocationAlert;

