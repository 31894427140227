// @flow
import React from 'react';
import { TouchableOpacity } from 'react-native';

type Props = {
  children?: any
};

const LinkButton = ({ children, ...props }: Props) => {

	const styleDefault = {
		padding:           '0.5em',
		background:        '#fff',
		borderTopWidth:    '1px',
		borderBottomWidth: '1px',
		borderColor:       'rgb(220,220,220)',
	};

	const propsNew = { ...props, style: { ...styleDefault, ...props.style } };

	return (<TouchableOpacity {...propsNew}>{children}</TouchableOpacity>);
};

export default LinkButton;
