import styled from 'styled-components/native';

export const Container = styled.SafeAreaView`
  flex: 1;
  padding: 20px;
`;

export const QuestionContainer = styled.View`
  margin-bottom: 15px;
  padding:15px;
  shadow-color: #000;
  shadow-offset: {
    width: 2,
    height: 2,
  };
  shadow-opacity: 0.2;
  shadow-radius: 4;
  elevation: 2;
`;

export const QuestionTitle = styled.Text`
  font-size: 16px;
  margin-bottom: 10px;
`;

export const InputContainer = styled.View`
  border-width: 1px;
  border-color: #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
`;

export const TextInput = styled.TextInput`
  font-size: 16px;
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #55595c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 2px;
`;

export const PickerContainer = styled.View`
  border-width: 1px;
  border-color: #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
`;

export const Picker = styled.Picker`
  font-size: 16px;
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #55595c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 2px;
`;

export const ButtonContainer = styled.View`
  margin-top: 12px;
`;

export const Button = styled.TouchableOpacity`
background-color: ${({ disabled }) => (disabled ? 'gray' : '#2ae3a2')};
padding: 10px;
border-radius: 5px;
opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const ButtonText = styled.Text`
  font-size: 18px;
  color: #fff;
  text-align: center;
`;

export const NotificationView = styled.View`
  border-radius: 5px;
  elevation: 5;
  margin: 5px;
  padding: 5px;
`;

export const NotificationText = styled.Text`
  color: #333;
  font-weight:bold;
  font-size: 16px;
  text-align: center;
`;