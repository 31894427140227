// @flow
import React from 'react';
import { connect } from 'react-redux';
import { View, Text, TouchableOpacity } from 'react-native';
import * as IO5 from 'react-icons/io5';
import snip     from 'styles/snip.js';

type Props = {
	onPress:      Function,
	item:         Object,
	selectedItem: Object
};

const styleItem = {
	padding:         '0.5rem',
	paddingLeft:     '0.75rem',
	background:      '#fff',
	borderRadius:    '0px',
	borderBottom:    snip.bdr,
};

const Txt = (props) => {
	const propsNew = { ...props, style: { fontSize: 'inherit', ...props.style } };
	return <Text {...propsNew} />;
};

class LineItem extends React.Component {
	props: Props;
	state: State;

	constructor(props) {

		super(props);

		this.state = {
		};
	}

	render() {
		const { onPress, item, selectedItem } = this.props;
		return (
			<TouchableOpacity
				onPress={() => onPress(item)}
				style={{ ...styleItem }}
			>
				<View style={{ flexDirection: 'row', alignItems: 'center' }}>
					<View style={{ flex: 1, fontSize: '14pt' }}>
						<Txt>{item.name}</Txt>
					</View>
					<View style={{ justifyContent: 'center' }}>
						{selectedItem.id === item.id ?
							<IO5.IoCheckmark size="2.3em" color="rgb(0, 122, 255)" /> :
							<IO5.IoCheckmark size="2.3em" color="rgb(0, 0, 0, 0)" />
						}
					</View>
				</View>
			</TouchableOpacity>
		);
	}
}

export default connect((state) => ({}), {})(LineItem);
