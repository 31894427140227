// @flow
import React, { Component } from 'react';
import { FlatList }         from 'react-native';
import { Attestation }       from 'types';
import { fetchConstants }   from 'actions/session.js';
import AttestationItem       from 'components/AttestationItem';

type Props = {
	attestations: Array<Attestation>,
};

type State = {
	inspClsLabel: Array<Object>,
};

class AttestationList extends Component {

	props: Props;
	state: State;

	constructor(props: Props) {

		super(props);
		this.state = { inspClsLabel: [] };
	}

	componentDidMount() {

		fetchConstants()
			.then((consts) => {

				const { inspClsLabel = null } = consts;

				if( inspClsLabel !== null )
					this.setState({ inspClsLabel });
			});
	}

	render() {

		const { attestations, history } = this.props;
		const { inspClsLabel } = this.state;

		return (
			<FlatList
				data={ attestations }
				extraData={ inspClsLabel }
				keyExtractor={(item, index) => item.id.toString()}
				renderItem={(lstObj) =>
					<AttestationItem
						attestation={lstObj.item}
						onPress={() => history.push(`/showAttestation/${lstObj.item.id}`)}
						inspClsLabel={ inspClsLabel }
					/>
				}
			/>
		);
	}
}

export default AttestationList;