import React from 'react';
import { View } from 'react-native';

type Props = {
	fnSubmitCanvas: Function,
	fnUpdateNavbar: Function,
	fnGetGeo:       Function,
	fnErrAlert:     Function, // (title, msg)
	fnGoBack:       Function,
	isConstruction: boolean,
};

class CamFileInput extends React.Component {

	props: Props;

	constructor(props) {

		super(props);

		this.refCanvas    = React.createRef();
		this.refFileInput = React.createRef();

		this.state = { canvWidth: 0, canvHeight:0 };
	}

	updateNavbar = (bRecord) => {

		const { fnUpdateNavbar, fnSubmitCanvas } = this.props;

		fnUpdateNavbar(
			bRecord,
			this.takePhoto,
			(evt) => { fnSubmitCanvas(this.refCanvas.current, this.takePhoto); }
		);
	};

	componentDidMount() {
		this.takePhoto();
	}

	// CALLED FROM NAVBAR BUTTONS
	takePhoto = (evt) => {

		this.updateNavbar(true);

		// NOTE: has to be within a handler of a user-initiated event
		this.refFileInput.current.click();
	};

	onPhotoInputChange = (evt) => {

		const { fnErrAlert, fnGetGeo, fnGoBack } = this.props;

		const arFiles = evt.target.files;

		// USUALLY A CANCELLATION, SO NO ERROR REPORT
		if( arFiles.length === 0 ) {
			fnGoBack();
			return;
		}

		if( arFiles.length > 1 ) {

			fnErrAlert('Too Many', 'Please choose one image per upload.');
			return;
		}

		if( !arFiles[0].type.match('image.*') ) {

			fnErrAlert('Incorrect File Type', 'Only image files are allowed.');
			return;
		}

		// GET GEOLOCATION BEFORE CAPTURE, PROCEED REGARDLESS
		// (should have at least one good point since app init)
		return fnGetGeo()
			.finally(() => {

				// READ FILE INTO <IMG> TAG, SWAP NAVBAR TO RE-TAKE MODE ON SUCCESS
				let reader = new FileReader();
				reader.onload = (fileEvt) => {

					let img = new Image();
					img.onload = () => {

						this.setState(
							{ canvWidth: img.naturalWidth, canvHeight: img.naturalHeight },
							() => {

								let oCanv = this.refCanvas.current;

								if(oCanv !== null){
									oCanv.width  = img.naturalWidth;
									oCanv.height = img.naturalHeight;
									oCanv.getContext('2d').drawImage(img, 0, 0);
								}
							}
						);
						this.updateNavbar(false);
					};

					img.src = fileEvt.target.result;
				}

				reader.readAsDataURL(arFiles[0]);
			});
	};

	render() {

		const styleCanvas = {
			width:  '100%',
			height: 'auto',
			margin: '0px',
		};

		const { canvWidth, canvHeight } = this.state;
		const { fnGoBack} = this.props;

		this.theFile = document.getElementById('theFile');
		document.body.onfocus = async () => {
			await new Promise(res => setTimeout(res, 500));
			if(!this.theFile.value.length) {
				sessionStorage.removeItem('SelectedLineItem');
				fnGoBack();
			}
			document.body.onfocus = null;
		};

		return (
			<View>
				<input
					type="file" accept="image/*" capture="environment" id="theFile"
					ref={this.refFileInput}
					onChange={this.onPhotoInputChange}
					style={{ display: 'none' }}
				/>
				<canvas ref={this.refCanvas} style={styleCanvas} width={canvWidth} height={canvHeight} />
			</View>
		);
	}
}

export default CamFileInput;
