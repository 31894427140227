let DBG_BUILD = true;
//let API_URL   = 'https://dev.waivit.net/api';
let API_URL = "https://localhost/api";

switch (process.env.NODE_ENV) {
  case "production":
    // eslint-disable-next-line
    switch (process.env.REACT_APP_BUILDMODE) {
      case "prod":
        API_URL = "https://waivit.net/api";
        DBG_BUILD = false;
        break;
      case "sit":
        API_URL = "https://dev.waivit.net/api";
        DBG_BUILD = false;
        break;
      case "uat":
        API_URL = "https://test.waivit.net/api";
        DBG_BUILD = false;
        break;
    }

    break;

  case "development":
  default:
    API_URL = "/api";
    break;
}

export { DBG_BUILD, API_URL };
