// @flow
import React from 'react';
import { connect }           from 'react-redux';
import { View } from 'react-native';
import { Inspection, User }  from 'types';
import { fetchInspections }  from 'actions/inspection';
import { uploadOfflineData2 } from 'actions/pending';
import InspectionList        from 'components/InspectionList';
import NoResults             from 'components/NoResults';
import Dialog                from 'components/Dialog';
import LS        from 'utils/localStore.js';
import OS        from 'utils/offlineStore.js';
import { isOnline } from 'utils';

type Props = {
	currentUser: User,
	arInsp:      Array<Inspection>,
	fetchInspections: Function,
};

type State = {
	uploading: Boolean,
}

class Inspections extends React.Component {

	props: Props;
	state: State;

	constructor(props) {

		super(props);

		this.state = {
			uploading: false,
		};
	}

	componentDidMount() {
		this.handleRefresh();
		this.isOfflineDataSyncing();
		// this.syncOfflineData();
	}

	syncOfflineData = () => {
		// Upload offline data when application in online
		// localStorage.setItem('isUploading', 'false');
		setInterval(() => {
			LS.Get('auth', 1)
			.then((authRet) => {
				if(authRet) {
					OS.All('pendingAPI')
					.then((requests) => {
						if(isOnline() && requests.length > 0 && localStorage.getItem('isUploading') === 'false') {
							localStorage.setItem('isUploading', 'true');
							this.setState({ uploading: true });
							// requestIdleCallback(uploadOfflineData2, { timeout: 1000 });
							uploadOfflineData2();
						} else {
							this.setState({ uploading: false });
						}
					});
				} else {
					this.setState({ uploading: false });
				}
			})
			.catch(err => Promise.reject(err));
		}, 5000);
	}

	isOfflineDataSyncing = () => {
		setInterval(() => {
			if(localStorage.getItem('isUploading') === 'true') {
				this.setState({ uploading: true });
			} else {
				this.setState({ uploading: false });
			}
		}, 5000);
	}

	handleRefresh = () => {
		return this.props.fetchInspections(this.props.currentUser.id);
	};

	render() {

		const { arInsp } = this.props;
		const { uploading } = this.state;

		return (
			<View>
				<Dialog
					visible={uploading}
					message={`The data sync is in progress. Please wait...`}
					uploading
				/>
				{(arInsp.length === 0) &&
					<NoResults label="No Results">
						Home inspection requests will appear here in your queue
					</NoResults>
				}
				{(arInsp.length > 0) && <InspectionList {...this.props} inspections={arInsp} />}
			</View>
		);
	}
}

export default connect(
	(state) => ({
		arInsp:      state.inspection.pending,
		currentUser: state.session.currentUser,
	}),
	{
		fetchInspections,
	}
)(Inspections);

