// @flow
import React from 'react';
import { connect } from 'react-redux';
import { View } from 'react-native';
import filter from 'lodash/filter';
import snip                 from 'styles/snip.js';
import { Inspection, User } from 'types';
import { fetchInspections } from 'actions/inspection';
import InspectionList       from 'components/InspectionList';
import NoResults            from 'components/NoResults';
import TabNav               from 'components/TabNav';
import { SET_HISTORY_TAB_IX, SET_HISTORY_SECTION_INDEX } from 'constants.js';
import HistoryTabs from 'components/HistoryTabs';
import AttestationList from 'components/AttestationList';
import { fetchAttestations } from 'actions/attestation';

type Props = {
	currentUser:      User,
	fetchInspections: Function,
	arInsp:           Array<Inspection>,
};

class InspectionHistory extends React.Component {

	props: Props;

	componentDidMount() {
		this.handleRefresh();
	}

	handleRefresh = () => {
		return new Promise((fnOK)=>{
			this.props.fetchInspections(this.props.currentUser.id);
			this.props.fetchAttestations(this.props.currentUser.id);
			fnOK();
		})
	};

	fnClick = (ix) => {
		const { historyTabIndex, setTabIx } = this.props;
		return () => {
			if(historyTabIndex !== ix){
				setTabIx(ix);
			}
		}
	};

	sectionClick = (index) =>{
		const { historySectionIndex, setSectionIndex} = this.props;
		return () => {
			if (historySectionIndex !== index){
				setSectionIndex(index);
			}
		}
	}

	render() {

		const { arInsp, historyTabIndex, historySectionIndex, arAttestations } = this.props;

		let inspections;
		let attestations;
		switch( historyTabIndex ) {

		case 0:
			inspections = filter(arInsp, { status: 'review' });
			attestations = filter(arAttestations, { status: 'review' });
			break;

		case 1:
			inspections = filter(arInsp, { status: 'completed' });
			attestations = filter(arAttestations, { status: 'completed' });
			break;

		default:
			inspections = arInsp;
			attestations = arAttestations;
			break;
		}

		const mainSections = ['Inspections', 'Attestations'];
		const tabs = ['Review', 'Completed', 'All'];

		return (
			<View>
				<HistoryTabs
					tabs={mainSections}
					onClick={this.sectionClick}
					isSelected={(ix) => (ix === historySectionIndex)}
				/>
				<TabNav
					tabs={tabs}
					onClick={this.fnClick}
					isSelected={(ix) => (ix === historyTabIndex)}
					containerStyle={{ flexDirection: 'row-reverse', borderTop: snip.bdr }}
				/>
				{historySectionIndex === 0 &&
					<View>
						{(inspections.length === 0) &&
					<NoResults label="No Results">
						Find completed inspections here after submission
					</NoResults>
					}
					{(inspections.length > 0) && <InspectionList {...this.props} inspections={inspections} />}
					</View>
				}
				{historySectionIndex === 1 &&
					<View>
						{(attestations.length === 0) &&
					<NoResults label="No Results">
						Find completed attestations here after submission
					</NoResults>
					}
					{(attestations.length > 0) && <AttestationList {...this.props} attestations={attestations}/>}
					</View>
				}
			</View>
		);
	}
}

export default connect(
	(state) => ({
		arInsp:          state.inspection.history,
		arAttestations:  state.attestation.history,
		historyTabIndex: state.inspection.historyTabIndex,
		historySectionIndex: state.inspection.historySectionIndex,
		currentUser:     state.session.currentUser,
	}),
	{
		fetchInspections,
		fetchAttestations,
		setTabIx: (index) => { return (dispatch) => dispatch({ type: SET_HISTORY_TAB_IX, index }); },
		setSectionIndex: (index) => {return (dispatch) => dispatch({type: SET_HISTORY_SECTION_INDEX, index})}
	}
)(InspectionHistory);

