// @flow
import React, { Component } from 'react';
import { connect }        from 'react-redux';
import { forgotPassword } from 'actions/session';
import { setNavButtons }  from 'reducers/navbar.js';
import snip               from 'styles/snip.js';
import Input              from 'components/Input';
import Button             from 'components/Button';
import SceneCommon        from 'containers/SceneCommon';

type Props = {
  forgotPassword: Function,
	setNavButtons:  Function,
};

type State = {
  email:      string,
  password:   string,
  submitting: boolean,
};

class EvpForgotPassword extends Component {

  state: State;
  props: Props;

  constructor(props: Props) {

    super(props);

    this.state = {
      email:      '',
      password:   '',
      submitting: false,
    };
  }

	componentWillUnmount() {
		this.props.setNavButtons(null);
	}

  componentDidMount() {
		this.props.setNavButtons(['back', 'Reset Password']);
	}

  handleSubmit = () => {
    this.setState({ submitting: true });
    this.props.forgotPassword({ email: this.state.email, baseurl: window.location.origin })
			.then(() => {
				this.props.history.goBack();
			})
      .catch(() => {
        this.setState({ submitting: false });
      });
  };

  render() {

		const margin = snip.pad;

    return (
      <SceneCommon>
				<Input
					label="E-Mail"
					placeholder="you@example.com"
					style={{ marginBottom: margin }}
					onChangeText={(value) => this.setState({ email: value })}
				/>
				<Button
					color="blue"
					disabled={this.state.submitting}
					animating={this.state.submitting}
					onPress={this.handleSubmit}
					style={{ margin }}
				>Submit</Button>
      </SceneCommon>
    );
  }
}

export default connect(null, { forgotPassword, setNavButtons })(EvpForgotPassword);
