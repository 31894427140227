import React from 'react';
import { connect } from 'react-redux';
import { setNavButtons, backBtn }       from 'reducers/navbar.js';
import { fetchInspection, uploadImage } from 'actions/inspection.js';
import { createEntry, updateEntry }     from 'actions/loan';
import { showAlert }      from 'actions/alert.js';
import { fetchLoan }      from 'actions/loan';
import * as GEO           from 'utils/geocode.js';
import ImgInstruct        from 'components/ImgInstruct';
import CamWebRTC          from 'scenes/CamWebRTC';
import CamFileInput       from 'scenes/CamFileInput';
import SceneCommon        from 'containers/SceneCommon';
import SelectItem         from 'components/SelectItem';
import DialogLabel        from 'components/DialogLabel';
import { View, Text }     from 'react-native';
import * as UTL           from 'utils';

const bHasWebRTC = false; // (typeof window.navigator.mediaDevices === 'object');

type Props = {
	history:         Object,
	currentUser:     Object,
	fetchInspection: Function,
	uploadImage:     Function,
	setNavButtons:   Function,
	fetchLoan:       Function,
	createEntry:     Function,
	updateEntry:     Function,
	selectLineItem:  Function,
	onChangePage:    Function,
	currentLoan:     Object,
	lineItems:       Array<Object>,
	inspEntries:     Array<Object>,
	inspection:      Object,
	photoOpts:       Object,
};

type State = {
	canvWidth:  number,
	canvHeight: number,
	pkey:       number,
	ptype:      string,
	inspection: Object,
	inspClsToPhotoType: Object,
	tempCanvas: any,
	activePage: string,
	imageLabel: string,
	selectedLineItem:   Object,
	showLabelDialog:    boolean,
};

class EvpCamera extends React.Component {

	props: Props;
	state: State;

	constructor(props) {

		super(props);

		this.refCanvOffscreen = React.createRef();
		this.refCamFileInput  = React.createRef();

		this.state = {
			canvWidth:  0,
			canvHeight: 0,
			pkey:       -1,
			ptype:      '',
			inspection: null,
			inspClsToPhotoType: {},
			tempCanvas: null,
			activePage: 'TakePhoto',
			imageLabel: '',
			selectedLineItem:   {},
			showLabelDialog: false,
		};
	}

	componentWillUnmount() {
		this.props.setNavButtons(null);
	}

	componentDidMount() {
		const { inspection, photoOpts } = this.props;
		const { type, key } = photoOpts;
		this.setState({ inspection, ptype: type, pkey: parseInt(key) });
	}

	fnUpdateNavbar = (bRecord, fTakePhoto, fSubmitPhoto) => {
		const { onChangePage } = this.props;
		const { activePage } = this.state;
		if( bRecord === true ) {
			this.props.setNavButtons([
				backBtn(() => onChangePage('ShowInspection'), 'Back'),
			]);
		}
		else {
			if(UTL.isMobileDevice() && activePage !== 'SelectLineItem') {
				fSubmitPhoto();
			} else {
				this.props.setNavButtons([
					backBtn(fTakePhoto, 'Re-Take'),
					{	label: 'Use Photo', action: fSubmitPhoto },
				]);
			}
		}
	};

	fnGetGeo = () => {

		return GEO.getPosition()
			.catch((err) => {

				const title = 'Image Capture';
				const { code = -1, message = 'unknown' } = err;

				console.error(title, err);
				this.fnErrAlert(title, `[${code}] ${message}`);

				return Promise.reject(err);
			});
	};

	fnCapCanvasDims = (canvOrig) => {

		const xo = canvOrig.width;
		const yo = canvOrig.height;
		const biggest = Math.max(xo, yo);
		const maxDim = 1920;

		return new Promise((fnOK) => {

			if( biggest <= maxDim ) {

				fnOK(canvOrig);
				return;
			}

			// RESIZE TO OFFSCREEN CANVAS IS LONGEST SIDE > maxDim
			const ratio = xo / yo;
			const xnew = (xo > yo) ? maxDim : (maxDim * ratio);
			const ynew = (xo > yo) ? (maxDim / ratio) : maxDim;

			this.setState(
				{ canvWidth: xnew, canvHeight: ynew },
				() => {
					let cTemp = this.refCanvOffscreen.current;
					if (cTemp !== null){
						cTemp.getContext('2d').drawImage(canvOrig, 0, 0, xnew, ynew);
						fnOK(cTemp);
					}
				}
			);
		});
	};

	fnPreSubmitCanvas = (canvOrig) => {
		const { ptype } = this.state;
		if(ptype === 'More') {
			this.setState({ tempCanvas: canvOrig, showLabelDialog: true });
		} else {
			this.fnSubmitCanvas(canvOrig);
		}
	}

	fnSubmitCanvas = (canvOrig) => {
		this.props.setNavButtons([backBtn(()=>{}, 'Photos')]);

		return this.fnCapCanvasDims(canvOrig)
			.then((canvResized) => {

				return new Promise((fnOK, fnERR) => {

					const fSave = (oBlob) => {

						const { currentUser, uploadImage, history } = this.props;
						const { ptype, pkey, inspection, imageLabel } = this.state;
						const { coords: { latitude = null, longitude = null } } = GEO.getLastGeo();

						const photoType = imageLabel ? imageLabel : ptype;
						// NOTE: current server-side image size limitation is 2MB
						// NOTE: don't propagate promises, since this is ultimately inside the canvas callback
						uploadImage(currentUser, inspection, oBlob, photoType.replace(/([^\w ]|_)/g, '').trim().split(" ").join("_"), pkey, latitude, longitude)
							.then(async (ret) => {
								const { id, inspection_id } = ret.data;
								fnOK(ret);
								await new Promise(res => setTimeout(res, 1000));
								history.push(`/showImage/${inspection_id}/${id}`);
							})
							.catch((err) => {

								// TODO: better error handling
								console.error('image: upload err', err);
								fnERR(err);
							});
					};
					canvResized.toBlob(fSave, 'image/jpeg', 0.85);
				});
			});
	};

	// For construction type loan
	fnSubmitCanvas2 = (canvOrig) => {

		return this.fnCapCanvasDims(canvOrig)
			.then((canvResized) => {

				return new Promise((fnOK, fnERR) => {

					const fSave = async (oBlob) => {
					const { currentUser, uploadImage, /*history*/ } = this.props;
						const { inspection, selectedLineItem } = this.state;
						const { coords: { latitude = null, longitude = null } } = GEO.getLastGeo();

						var ptype = selectedLineItem.name;
						var pkey = selectedLineItem.id;
						// NOTE: current server-side image size limitation is 2MB
						// NOTE: don't propagate promises, since this is ultimately inside the canvas callback
						uploadImage(currentUser, inspection, oBlob, ptype.replace(/([^\w ]|_)/g, '').trim().split(" ").join("_"), pkey, latitude, longitude)
							.then((ret) => {
								// const { id, inspection_id } = ret.data;
								fnOK(ret);
								sessionStorage.setItem('SelectedLineItem', JSON.stringify(selectedLineItem));
								// history.push(`/showImage/${inspection_id}/${id}`);
								this.props.onChangePage('ShowInspection')
							})
							.catch((err) => {

								// TODO: better error handling
								console.error('image: upload err', err);
								fnERR(err);
							});

					};
					canvResized.toBlob(fSave, 'image/jpeg', 0.85);
				});
			});
	};

	fnSwitchPage = (page, disabled = true, canvOrig = null) => {
		if(page === 'SelectLineItem') {
			if(this.state.selectedLineItem.id)
				disabled = false;
			this.props.setNavButtons([
				backBtn(() => { 
					this.setState({ activePage: 'TakePhoto' });
					this.refCamFileInput.current.updateNavbar(false);
					UTL.scrollToTop();
				}, 'Back'),
				'Label',
				{	
					label: 'Next',
					action: () => {
						this.fnSubmitCanvas2(this.state.tempCanvas);
					},
					disabled: disabled,
				},
			]);
			if(canvOrig)
				this.setState({ tempCanvas: canvOrig });
			this.setState({ activePage: page });
			UTL.scrollToTop();
		}
	}

	fnSelectLineItem = (item) => {
		this.setState({ selectedLineItem: item });
		this.fnSwitchPage('SelectLineItem', false);
	}

	fnErrAlert = (title, msg) => this.props.showAlert(msg, title, 'err');

	render() {

		const opts = {
			fnSubmitCanvas: this.fnPreSubmitCanvas,
			fnGetGeo:       this.fnGetGeo,
			fnUpdateNavbar: this.fnUpdateNavbar,
			fnErrAlert:     this.fnErrAlert,
			fnGoBack:       () => { this.props.onChangePage('ShowInspection') },
			ref:            this.refCamFileInput,
			isConstruction: false,
		};

		if( this.state.inspection === null )
			return null;

		if(this.state.inspection.loan.type === 'Construction')
			opts.isConstruction = true;

		var addMoreImage = sessionStorage.getItem('SelectedLineItem');
		if(this.state.inspection.loan.type === 'Construction' && !addMoreImage)
			opts.fnSubmitCanvas = (canvOrig) => this.fnSwitchPage('SelectLineItem', true, canvOrig);

		// FETCH IMAGE INSTRUCTIONS
		const { pkey, inspection, inspClsToPhotoType, canvWidth, canvHeight,
			activePage, selectedLineItem, showLabelDialog, imageLabel, tempCanvas } = this.state;
		const { lineItems } = this.props;
		const arPtypes = inspClsToPhotoType[inspection.insp_cls];
		const pinfo = Array.isArray(arPtypes) ? arPtypes.find((V) => (V.key === pkey)) : null;

		return (
			<React.Fragment>
				<DialogLabel
					visible={showLabelDialog}
					label={imageLabel}
					onChange={(value) => this.setState({ imageLabel: value })}
					onReject={() => this.setState({ showLabelDialog: false })}
					onAccept={() => this.setState({ showLabelDialog: false }, () => { this.fnSubmitCanvas(tempCanvas) })}
				/>
				<SceneCommon ref={this.refContainer} style={{ backgroundColor: 'black', display: activePage === 'TakePhoto' ? 'block' : 'none' }}>
					{ (pinfo !== null) && <ImgInstruct {...pinfo} /> }
					{ bHasWebRTC && <CamWebRTC { ...opts } /> }
					{ !bHasWebRTC && <CamFileInput { ...opts } /> }
					<canvas ref={this.refCanvOffscreen} style={{ display: 'none' }} width={canvWidth} height={canvHeight} />
				</SceneCommon>
				{activePage === 'SelectLineItem' &&
					<SceneCommon ref={this.refContainer}>
						{lineItems.filter(li => li.allocation < 100).length > 0 ?
							(lineItems
							.filter(li => li.allocation < 100)
							.map((item, index) => {
								return(
									<SelectItem
										key={'LineItem-' + index}
										item={item}
										selectedItem={selectedLineItem}
										onPress={this.fnSelectLineItem}
									/>
								);
							})) : 
							(
								<View style={{alignItems: 'center'}}>
									<Text>All line items are 100% completed.</Text>
								</View>
							)
						}
					</SceneCommon>
				}
			</React.Fragment>
		);
	}
}

export default connect(
	(state) => ({
		currentUser: state.session.currentUser,
		currentLoan: state.loan.currentLoan,
		lineItems:   state.loan.lineItems,
		inspEntries: state.loan.inspEntries,
	}), {
		fetchInspection,
		uploadImage,
		setNavButtons,
		showAlert,
		fetchLoan,
		createEntry,
		updateEntry,
	}
)(EvpCamera);

