import axios             from 'axios';
import cookie            from 'react-cookies';
import LS                from 'utils/localStore.js';
import OS                from 'utils/offlineStore.js';
import { isOnline,findHost }      from 'utils';
import { showAlert }     from 'actions/alert';
import { fetchInspections } from 'actions/inspection';
import { fetchAttestations } from 'actions/attestation';
import {
	LOGIN, LOGOUT,
  UPDATE_PROFILE_SUCCESS,
} from 'constants.js';

function setLoginState(dispatch, authRet, bForceInspectionRefresh = false, fetchInsp = true) {

	if(isOnline()) {
		localStorage.setItem('token', JSON.stringify(authRet.meta.token));
		axios.defaults.headers.common.Authorization = `Bearer ${authRet.meta.token}`;

		dispatch({ type: LOGIN, user: authRet.data });

		// TODO: handle fetchInspections errors
		if(fetchInsp) {
			return fetchInspections(authRet.data.id, bForceInspectionRefresh)(dispatch)
				.then(()=> fetchAttestations(authRet.data.id, bForceInspectionRefresh)(dispatch))
				.catch((err) => {

					if( err.message )
						alert(err.message);
				});
		}
		return Promise.resolve(authRet);
	} else {
		dispatch({ type: LOGIN, user: authRet.data });

		// TODO: handle fetchInspections errors
		if(fetchInsp) {
			return fetchInspections(authRet.data.id, bForceInspectionRefresh)(dispatch)
				.catch((err) => {

					if( err.message )
						alert(err.message);
				});
		}
	}
}

function setRemoveCookie(token=null) {
	var hostname = window.location.hostname;
	const expires = new Date()
	expires.setFullYear(expires.getFullYear() + 2);

	var remove_options = {
		path: '/',
		domain: hostname.split('.')[0] === 'localhost' ? 'localhost' : '.waivit.net',
	}
	if(hostname.includes('waivit.net')) {
		remove_options.domain = ".waivit.net";
	}
	var options = {
		...remove_options,
		expires,
		secure: true
	}

	if(token) {
		switch(findHost(hostname)) {
			case 'localhost': 
				cookie.save('local_token', token, { ...options });
				break;
			case 'dev': 
				cookie.save('dev_token', token, { ...options });
				break;
			case 'test': 
				cookie.save('test_token', token, { ...options });
				break;
			default: 
				cookie.save('token', token, { ...options });
				break;
		}
	} else {
		switch(findHost(hostname)) {
			case 'localhost': 
				cookie.remove('local_token', { ...remove_options });
				break;
			case 'dev': 
				cookie.remove('dev_token', { ...remove_options });
				break;
			case 'test': 
				cookie.remove('test_token', { ...remove_options });
				break;
			default: 
				cookie.remove('token', { ...remove_options });
				break;
		}
	}
}

function setLogin(dispatch, authRet, bForceInspectionRefresh = false) {

	if(authRet?.meta?.token) {
		setRemoveCookie(authRet.meta.token);
	}

	const oRET = { ...authRet, current: 1 };

	return LS.Put('auth', oRET)
		.then(() => setLoginState(dispatch, oRET, bForceInspectionRefresh))
		// .then(()=>{
		// 	fetchUserSignature(oRET.data.id)
		// 	.then((signatureBase64)=>{
		// 		const imgNew = {
		// 			id:            Date.now(),
		// 			user_id:       oRET.data.id,
		// 			file:          "user_signature.jpg",
		// 			thumbnail:     signatureBase64,
		// 		};
		// 		try{
		// 			LS.Put('auth', { ...imgNew, current: 2 });
		// 		}catch(err){
					
		// 		}
		// 	});
		// })
		.then(() => Promise.resolve(oRET));
}

function clearLogin(dispatch) {


	LS.Delete('auth', 1);
	axios.defaults.headers.common.Authorization = null;
	dispatch({ type: LOGOUT });
}

// NOTE: currently only called on app init
export function authRefresh(store, fetchInsp = true) {

	return LS.Get('auth', 1)
		.then((authRet) => {

			if( !authRet ) {
				clearLogin(store.dispatch);
				return Promise.reject({ message: 'not authenticated' });
			}

			return setLoginState(store.dispatch, authRet, false, fetchInsp)
				.then(() => Promise.resolve(authRet));
		})
		.catch(err => Promise.reject(err));
}

// ATTEMPT REFRESH OF JWT AUTH TOKEN
export function refreshAuthToken(store, token) {

	const oHDR = { headers: { Authorization: `Bearer ${token}` } };

	return axios.post('/sessions/refresh', {}, oHDR)
		.then((rsp) => {
			return setLogin(store.dispatch, rsp, true);
		})
		.catch((err) =>  {
			clearLogin(store.dispatch);
			return Promise.reject(err);
		});
}

export function login(data) {

	return (dispatch) => {
		if(isOnline()) {
			return axios.post('/sessions', data)
			.then((rsp) => {
				OS.Put('login', { data: { ...rsp.data, password: data.password }, meta: { ...rsp.meta } });
				return setLogin(dispatch, rsp, true);
			})
			.catch((err) =>  {
				clearLogin(dispatch);
				return Promise.reject(err);
			});
		} else {
			return OS.Get('login', data.email.replace(' ', ''))
			.then((login) => {
				if(login) {
					if(login.data.password === data.password)
						return setLogin(dispatch, login, true);
				} else {
					clearLogin(dispatch);
					showAlert(
						'Login not found.',
						'Login Failed',
						'err'
					)(dispatch);
					return Promise.reject({ error: 'login not found' });
				}
			});
		}
	};
}

export function signup(data) {

	return (dispatch) =>
		axios.post('/registrations', data)
			.then((rsp) => {
				return setLogin(dispatch, rsp, true);
			});
}

export function logout() {

	return (dispatch) => {

		return axios.delete('/sessions')
			.finally(() => {
				setRemoveCookie();
				clearLogin(dispatch);
				LS.Destroy();
			});
	};
}

export function forgotPassword(data) {

	return (dispatch) =>
		axios.post('/passwords/forgot', data)
			.then((ret) => {
				showAlert(
					'Please check your e-mail inbox for further instructions.',
					'Password Reset Request',
					'ok'
				)(dispatch);
				return Promise.resolve(ret);
			});
}

export function fetchConstants(force = false) {

	if(isOnline()) {
		return LS.Get('consts', 1)
			.then((consts) => {

				if( consts && !force )
					return Promise.resolve(consts);

				return axios.get('/constants')
					.then((rsp) => {
						OS.Put('consts', { ...rsp, current: 1 });
						return LS.Put('consts', { ...rsp, current: 1 }).finally(() => Promise.resolve(rsp));
					});
			});
	} else {
		return OS.Get('consts', 1)
			.then((rsp) => {
				if( rsp ) {
					LS.Put('consts', { ...rsp, current: 1 });
					return Promise.resolve(rsp);
				}
				return Promise.reject({ error: 'Failed to load app constants' });
			});
	}
}

export function updateProfile(id, data) {

  return (dispatch) => {
		return axios.patch(`/users/${id}`, data)
      .then((rsp) => {

				// UPDATE CACHED USER
				return LS.Get('auth', 1)
					.then((authRet) => {

						if( !authRet )
							return Promise.reject({ message: 'updateProfile: user not cached' });

						authRet.data = rsp.data;

						return LS.Put('auth', authRet).then(() => {

							dispatch({ type: UPDATE_PROFILE_SUCCESS, user: rsp.data });
							return Promise.resolve(rsp);
						});
					})
					.catch(err => Promise.reject(err));
			});
  };
}

function saveSignatureLocal(params) {
	const { filename, user, blob } = params;

	return new Promise((fnOK, fnERR) => {
		let rdr = new FileReader();
		rdr.onload = (evt) => {
			// CREATE TEMPORARY IMAGE RECORD
			const imgNew = {
				id:            Date.now(),
				user_id:       user.id,
				file:          filename,
				thumbnail:     evt.target.result,
			};
			// ADD TO CACHE
			try{
				LS.Put('auth', { ...imgNew, current: 2 }).then(() => {
					fnOK(imgNew);
				});
				fnOK(imgNew);
			}catch(err){
				fnOK()
			}
		};
		rdr.readAsDataURL(blob);
	});
}


export const uploadSignature = (user, blob) => {
	return (dispatch) => {
		if(isOnline()){
			const refreshSignature = (filename, user_id) =>{
                return new Promise((fnOK, fnERR) => {
                    fetchUserSignature(user_id)
                    .then((signatureBase64)=>{
                        const imgNew = {
                            id:            Date.now(),
                            user_id:       user_id,
                            file:          filename,
                            thumbnail:     signatureBase64,
                        };
                        LS.Put('auth', { ...imgNew, current: 2 });
                        fnOK();
                    })
                    .catch(()=>fnERR());
                })
            }
				var filename = "user_signature.jpg";
				let apiPayload  = new FormData();
				apiPayload.append("IMAGE", blob);

				return axios.post(`/users/${user.id}/signature`, apiPayload)
				.then(()=> {
					return refreshSignature(filename, user.id)
				})
				.catch((ex) => {
					return refreshSignature(filename, user.id)
				});
		}else{
			return new Promise((fnOK, fnERR) => {
			  var filename = `user_sign_${user.id}.jpg`;
		
			  var pendingApiObject = {
				id: Date.now(),
				api: 'uploadSignature',
				url: `/users/${user.id}/signature`,
				user_id: user.id,
				data: {
				  blob
				}
			  };
			  saveSignatureLocal({ filename, user, blob })
				.then(() => {
				  try {
					OS.Put('pendingAPI', pendingApiObject);
					dispatch({ type: 'UPLOAD_SIGNATURE_SUCCESS', payload: pendingApiObject });
					fnOK(pendingApiObject);
				  } catch (err) {
					fnERR(err);
				  }
				})
				.catch((err) => {
				  fnERR(err);
				});
			});
		}
	};
  };
  

export function fetchUserSignature(userId){
	if(isOnline()){
		return new Promise(async(fnOK, fnERR)=>{
			try {
				const response = await axios.get(`/users/${userId}/signature`, {
				  responseType: 'blob'
				});
				const reader = new FileReader();
				reader.onloadend = () => {
					fnOK(reader.result);
				};
				reader.readAsDataURL(response);
			  } catch (error) {
				fnERR(error)
			  }
		})
	}else{
		Promise.reject({error : "unable to pull signature"})
	}
}