// @flow
import React from 'react';
import { View, Text, TouchableOpacity }         from 'react-native';
import * as IO5                                 from 'react-icons/io5';
import { statusColor, toYMD, transformInspCls } from 'utils';
import { Inspection }                           from 'types';
import snip                                     from 'styles/snip.js';

type Props = {
	onPress:      Function,
	inspection:   Inspection,
	inspClsLabel: Array<Object>,
};

const styleItem = {
	padding:         '0.5rem',
	paddingLeft:     '0.75rem',
	marginBottom:    '0.25rem',
	background:      '#fff',
	borderRadius:    '0px',
	borderTop:       snip.bdr,
	borderBottom:    snip.bdr,
	borderLeftStyle: 'solid',
	borderLeftWidth: '0.5rem',
};

const Txt = (props) => {
	const propsNew = { ...props, style: { fontSize: 'inherit', ...props.style } };
	return <Text {...propsNew} />;
};

const InspectionItem = ({ onPress, inspection, inspClsLabel }: Props) => {

	const { loan = null, organization = null } = inspection;
	const isStr = (x) => ((typeof x === 'string') && (x.trim().length > 0));

	return (
		<TouchableOpacity
			onPress={onPress}
			style={{ ...styleItem, borderLeftColor: statusColor(inspection.status) }}
		>
			<View style={{ flexDirection: 'row' }}>
				<View style={{ flex: 1, fontSize: '12pt' }}>
					{ organization && <Txt style={{ fontSize: '16pt', fontWeight: 'bold' }}>{organization.name}</Txt> }
					<Txt style={{ color: 'rgb(130,145,160)', marginBottom: '1em', fontSize: '11pt' }}>
						{ inspection.due_at  && toYMD(inspection.due_at) }
						{inspection.loan.type !== 'Construction' && (
							<React.Fragment>
								{ " - " }
								{ transformInspCls(inspClsLabel, inspection) }
							</React.Fragment>
						)}
					</Txt>
					{ loan &&
						<View>
							{ isStr(loan.address)     && <Txt>{loan.address}</Txt> }
							<Txt>{loan.city}, {loan.state} {loan.zip}</Txt>
						</View>
					}
				</View>
				<View style={{ justifyContent: 'center' }}>
					<IO5.IoArrowForward size="2em" color="rgb(180,190,200)" />
				</View>
			</View>
		</TouchableOpacity>
	);
}

export default InspectionItem;

