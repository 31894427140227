import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import colors from 'styles/colors';

const styleTxt = {
  flex: 1,
  paddingVertical: '0.7em',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
};

const styleLables = {
    fontSize: '13pt',
}

const styleContainer = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '100%',
};

type Props = {
};

type State = {
  activeTab: number,
};

class HistoryTabs extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { tabs, onClick, isSelected } = this.props;

    return (
      <View style={{ ...styleContainer }}>
        {tabs.map((title, index) => {
          const styleSel = isSelected(index) ? { color: 'white', backgroundColor: colors.evp_blue, fontWeight: 'bold' } : {};
          return (
            <TouchableOpacity
              key={index}
              style={{ ...styleTxt, ...styleSel }}
              onPress={onClick(index)}
            >
              <Text style={{...styleLables, ...styleSel}}>{title}</Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  }
}

export default HistoryTabs;