// @flow
import React    from 'react';
import { Text } from 'react-native';

const ImgInstruct = (props) => {

	// eslint-disable-next-line
	const { color, instructions, required, type } = props;

	const sDefault = {
		position:   'absolute',
		display:    'block',
		zIndex:     '750',
		top:        '0px',
		width:      '100%',
		padding:    '1rem',

		textAlign:  'center',
		color:      'white',
		fontWeight: 'bold',
		textShadow: '0px 0px 3px black, 2px 2px 3px black',
	};

	return ( <Text style={sDefault}>{ instructions }</Text> );
};

export default ImgInstruct;

