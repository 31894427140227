// @flow
import React from 'react';
import LDSH  from 'lodash';
import { View, Text, TouchableOpacity } from 'react-native';
import { Image as ImageType } from 'types.js';
import * as IO5 from 'react-icons/io5';

type Props = {
	image:             ?ImageType,
	onNavigateToImage: Function,
	allocation:        number,
};

const ImageItem2 = ({
	image,
	onNavigateToImage,
	allocation
}: Props) => {

	const styleTop = {
		position:     'relative',
		float:        'left',
		boxSizing:    'border-box',
		height:       '40vw',
		marginBottom: '5px',
	};

	const styleEmptySlot = {
		borderRadius:   '4px',

		position: 'absolute',
		top:      '0px',
		bottom:   '0px',
		left:     '0px',
		right:    '0px',

		display:        'flex',
		alignItems:     'center',
		justifyContent: 'center',
		padding:        '10px',
	};

	const styleComment = {
		position:   'absolute',
		bottom:     '0.5rem',
		right:      '0.5rem',
		display:    'inline-block',
		color:      'white',
		fontSize:   '1em',
	};

	const styleText = {
		fontSize:   '110%',
		lineHeight: '115%',
		color:      'inherit',
		textShadow: '0px 0px 4px black',
	};

	if( LDSH.isObjectLike(image) ) {

		const { thumbnail, comments } = image;

		return (
			<View>
				<TouchableOpacity style={styleTop} onPress={() => onNavigateToImage(image)}>
					<View style={{ ...styleEmptySlot, backgroundImage: `url("${thumbnail.includes('https') ? thumbnail : thumbnail.replace("http", "https")}")`, backgroundSize: 'cover' }}>
						{(comments.length > 0) &&
							<View style={styleComment}>
								<IO5.IoChatbubble style={{ marginRight: '0.4em' }} />
								<Text style={styleText}>{comments.length}</Text>
							</View>
						}
					</View>
				</TouchableOpacity>
				<View style={{alignItems: 'center'}}>
					<Text style={{ maxWidth:'90%', overflowWrap:'break-word'}}>{image.type} {isNaN(allocation) ? '' : ('- ' + allocation + '%')}</Text>
				</View>
			</View>
		);
	}

	return null;
};

export default ImageItem2;
