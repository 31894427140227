import colors from 'styles/colors.js';

const bdr = `1px solid ${colors.border}`;
const bdrRad = '3px';

const snip = {
	bdr,
	bdrRad,
	boxShad: '3px 3px 5px #000',
	pad:     '0.5rem',
	bdrAll:  {
		border: bdr,
		borderRadius: bdrRad,
	},
};

export default snip;

