// @flow
import * as React from 'react';
// import TabNav            from 'components/TabNav';
import Inspections       from 'scenes/Inspections';
import Profile           from 'scenes/Profile';
import InspectionHistory from 'scenes/InspectionHistory';
import SceneCommon       from 'containers/SceneCommon';
import Attestations      from 'scenes/Attestations';
import { connect } from 'react-redux';
import HistoryTabs from 'components/HistoryTabs';
import { View } from 'react-native';
import { SET_HISTORY_SECTION_INDEX } from 'constants.js';

// const tabs  = ['INSPECT', 'HISTORY', 'PROFILE'];
// const route = ['/inspect', '/history', '/profile'];

// const ixToRoute = (ix) => {

// 	if( (ix >= 0) && (ix < route.length) )
// 		return route[ix];

// 	return false;
// };

class EvpTabBar extends React.Component {

  render() {

		const path = this.props.match.path;

		const mainSections = ['Inspections', 'Attestations'];
		const { historySectionIndex, setSectionIndex, history } = this.props;
		// const fnSelTab = (ix) => {
		// 	return (evt) => {
		// 		const nextPath = ixToRoute(ix);
		// 		if( nextPath !== false )
		// 			this.props.history.push(nextPath);
		// 	}
		// };

		// const isSelected = (ix) => {
		// 	const psel = ixToRoute(ix);
		// 	return (psel === path);
		// };

		const sectionClick = (index) =>{
			return (evt) =>{

				switch(index){
					case 0: history.push('/inspect');
						break;
					case 1: history.push('/attestations');
						break;
					default: history.push('/inspect');
				}
				setSectionIndex(index);
			} 
		}

		let currentView;
		switch(path) {
			default:
			case '/inspect':
				currentView =
				<View>
					<HistoryTabs
						tabs={mainSections}
						onClick={sectionClick}
						isSelected={(ix) => (ix === historySectionIndex)}
					/>
					<Inspections {...this.props}/>
				</View>
				break;
			case '/attestations':
				currentView = 
				<View>
					<HistoryTabs
						tabs={mainSections}
						onClick={sectionClick}
						isSelected={(ix) => (ix === historySectionIndex)}
					/>
					<Attestations {...this.props}/>
				</View>
				break;
			case '/history':
				currentView = <InspectionHistory {...this.props}/>;
				break;
			case '/profile':
				currentView = <Profile {...this.props}/>;
				break;
		}

    return (
			<SceneCommon>
				{/* <TabNav
					tabs={tabs}
					onClick={fnSelTab}
					isSelected={isSelected}
				/> */}
				{ currentView }
			</SceneCommon>
    );
  }
}

export default connect((state)=>({
		historySectionIndex: state.inspection.historySectionIndex,
		currentUser:     state.session.currentUser,
}), {
	setSectionIndex: (index) => {return (dispatch) => dispatch({type: SET_HISTORY_SECTION_INDEX, index})}
})(EvpTabBar);

