import {
  ALERT,
  HIDE_ALERT,
} from 'constants.js';

export function showAlert(message, title, mode) {
	return (dispatch) => {
		dispatch({ type: ALERT, message, title, mode });
	};
}

export function hideAlert() {
	return (dispatch) => {
		dispatch({ type: HIDE_ALERT });
	};
}
