import { UPDATE_NAV } from 'constants.js';
import LDSH  from 'lodash';

const initialState = {
	buttons: [],
};

// eslint-disable-next-line
export default function (state = initialState, action) {
	switch (action.type) {
		case UPDATE_NAV:
			return {
				...state,
				buttons: Array.isArray(action.buttons) ? action.buttons : [],
			};
		default:
			return state;
	}
}

export const setNavButtons = (buttons) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_NAV, buttons });
	};
};

export const backBtn = (onClick, label = 'Back') => ({
	label,
	iconName: 'ios-arrow-back',
	action: LDSH.isFunction(onClick) ? onClick : ()=>{},
});

