import React, { Component } from 'react';
import { Provider }       from 'react-redux';
import { ThemeProvider }  from 'styled-components/native';
import Root               from './containers/Root';
import colors             from './styles/colors';
import store              from './store.js';

export default class App extends Component {

	constructor(props) {
		super(props);
		this.state = { error: null };
	}

	componentDidCatch(error, info) {
		this.setState({ error });
	}

  render() {

		const { error = null } = this.state;

		if( error ) {

			const sPre = { whiteSpace: 'pre-wrap', wordWrap: 'break-word' };

			return (
				<div style={{ fontFamily: 'monospace', fontSize: '11pt', padding: '1em', overflow: 'auto' }}>
					<p style={{ fontWeight: 'bold', marginTop: '0px' }}>{ error.name }</p>
					<pre style={sPre}>{ error.message }</pre>
				</div>
			);
		}

    return (
			<Provider store={store}>
				<ThemeProvider theme={colors}>
					<Root />
				</ThemeProvider>
			</Provider>
    );
  }
};

