import { LOGIN, LOGOUT, UPDATE_PROFILE_SUCCESS } from 'constants.js';

const initialState = {
	currentUser: null,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
	switch (action.type) {
		case LOGOUT:
			return { ...initialState };
		case LOGIN:
			return {
				...state,
				currentUser: action.user,
			};
		case UPDATE_PROFILE_SUCCESS:
			return {
				...state,
				currentUser: action.user,
			};
		default:
			return state;
	}
};

