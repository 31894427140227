import React, { useState } from "react";
import { View, TouchableOpacity, Text } from "react-native";

const RadioButton = ({ options, onClick }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onClick(option);
  };

  return (
    <View>
      {options.map((option, index) => {
        const isSelected = option === selectedOption;

        return (
          <TouchableOpacity
            key={`option-${index}`}
            onPress={() => handleOptionSelect(option)}
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginVertical: 10,
            }}
          >
            <View
              style={{
                height: 24,
                width: 24,
                borderRadius: 12,
                borderWidth: 2,
                borderColor: isSelected ? "#2ae3a2" : "grey",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isSelected && (
                <View
                  style={{
                    height: 12,
                    width: 12,
                    borderRadius: 6,
                    backgroundColor: "#2ae3a2",
                  }}
                />
              )}
            </View>
            <Text style={{ marginLeft: 10 }}>{option}</Text>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

export default RadioButton;
