import LDSH  from 'lodash';
import store from 'store.js';
import OS    from 'utils/offlineStore.js';
import { isOnline } from 'utils';
import { WAITING }  from 'constants.js';

export const PosOpts = {
	maximumAge: 10000,
	timeout:    28000,
	enableHighAccuracy: true,
};

var g_lastGeo = null;

export function getLastGeo() {
	if( LDSH.isObjectLike(g_lastGeo) )
		return LDSH.cloneDeep(g_lastGeo);

	return null;
}

export function getPosition() {

	return new Promise((fnOK, fnERR) => {

		// PROGRESS NOTIFIER
		store.dispatch({ type: WAITING, bWait: true, waitMsg: 'acquiring GPS location...' });

		if(isOnline()) {
			window.navigator.geolocation.getCurrentPosition(
				(pos) => {

					setTimeout(() => {
						// NOTE: Prototype BS w/ GeolocationPosition and descendents
						//       makes `pos` & `coords` behave differently from plain objects.
						//       Using `toPlainObject` to rectify.
						const newGeo = {
							coords:    LDSH.toPlainObject(pos.coords),
							timestamp: pos.timestamp,
						};

						g_lastGeo = newGeo;
						OS.Put('geolocation', { ...newGeo, current: 1 });
						
						store.dispatch({ type: WAITING, bWait: false });
						fnOK(newGeo);
					}, 700);

				},
				(err) => {

					store.dispatch({ type: WAITING, bWait: false });
					fnERR(err);
				},
				PosOpts
			);
		} else {
			OS.Get('geolocation', 1)
			.then((rsp) => {

				if( rsp ) {
					g_lastGeo = rsp;
					store.dispatch({ type: WAITING, bWait: false });
					fnOK(rsp);
				}
				else {
					store.dispatch({ type: WAITING, bWait: false });
					fnERR({error: 'Failed to load app constants'});
				}
			});
		}

	});
}

