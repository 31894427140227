export default function ServiceWorkerRegister() {
    const serviceWorkerPath = `${process.env.PUBLIC_URL}/service-worker.js`;
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', function () {
            navigator.serviceWorker.register(serviceWorkerPath).then(registration => {
            // console.log('SERVICE WORKER REGISTERED SUCCESSFULLY !!');
            // console.log(registration);
            }).catch(error =>{
            // console.log("SERVICE WORKER REGISTRATION FAILED !!");
            // console.log(error);
            })
        });
    }
}