// @flow
import React, { Component } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import snip   from 'styles/snip.js';

const Shadow = styled(View)`
	z-index:  200;
	position: fixed;
	top:      0px;
	bottom:   0px;
	width:    100%;
	padding:  1rem;

	display:  flex;
	flex-direction:  column;
	justify-content: center;
	align-items:     stretch;

	margin:           0px;
	background-color: rgba(0, 0, 0, 0.5);
`;

const AlertView = styled(View)`
	flex-direction:  column;
	justify-content: space-between;
	text-align: center;
	border:           ${props => snip.bdr};
	border-radius:    10px;
	background-color: white;
	width: fit-content;
	margin: auto;
`;

const Txt = styled(Text)`
	padding:   2rem 1rem;
	font-size: inherit;
`;

const BtnTxt = styled(Text)`
	padding:   0.7rem;
	font-size: inherit;
	color:     rgb(0, 122, 255);
`;

type State = {
	visible: boolean,
};

class LocationAlert extends Component {
	state: State;

	constructor(props) {

		super(props);

		this.state = {
			visible: true,
		};
	}

	onAccept = () => {
		this.setState({ visible: false });
	};

	render() {

		const { visible } = this.state;

		return (
			<React.Fragment>
				{visible &&
					<Shadow>
						<AlertView>
							<View>
								<Txt style={{ borderBottom: snip.bdr }}>
								{`Location access is required to geocode your photos. iPhone/Safari users: Open Settings, Select Privacy > Location Services > Safari Websites, Select While Using the App and enable Precise Location.`} <a href="https://yourevp.zendesk.com/hc/en-us/articles/9074330922899">Click here</a> {`for details & all other devices/browsers.`}
								</Txt>
								
								<TouchableOpacity
									onPress={() => this.onAccept()}
								>
									<BtnTxt>{'OK'}</BtnTxt>
								</TouchableOpacity>
							</View>
						</AlertView>
					</Shadow>
				}
			</React.Fragment>
		);
	}
}

export default LocationAlert;

