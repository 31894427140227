import axios from 'axios';
import LDSH  from 'lodash';
import { ALERT, WAITING, LOGOUT } from 'constants.js';
import { API_URL } from './index.js';
import LS from 'utils/localStore.js';

export function configureAxios(store) {

	axios.defaults.baseURL = API_URL;
	axios.defaults.headers.common['Content-Type'] = 'application/json';
	axios.defaults.headers.common['Accept'] = 'application/json';
	// axios.defaults.headers.post['Content-Type'] = 'application/json';

	axios.interceptors.request.use(
		(cfg) => {
			if(!(cfg.url.includes('images') && cfg.method === 'post')) {
				document.body.style.overflow = 'hidden';
				store.dispatch({ type: WAITING, bWait: true });
			}
			return cfg;
		},
		(err) => Promise.reject(err)
	);

	const fnDone = () => {
		document.body.style.overflow = 'auto';
		store.dispatch({ type: WAITING, bWait: false });
	};

	axios.interceptors.response.use(
		(rsp) => {

			fnDone();
			return Promise.resolve(rsp.data);
		},
		async (err) => {

			// TODO: clear auth in cache & redux on auth errors

			fnDone();

			const oRsp = LDSH.get(err, 'response.data', {});
			const nSts = LDSH.get(err, 'response.status', 500);
			const oRET = { ...oRsp, status: nSts };

			if( oRET.hasOwnProperty('error') ) {
				if(nSts === 401 ) {
					const hasTokenExpired = (inputString)=> /(token|expired)/i.test(inputString.toLowerCase());
					const logoutUser = ()=>{
						LS.Delete('auth', 1);
						localStorage.setItem('snapUnauthorized', true);
						axios.defaults.headers.common.Authorization = null;
						store.dispatch({ type: LOGOUT });
						localStorage.setItem('isUploading', 'false');
						LS.Destroy();
					}
					if(hasTokenExpired(oRET.error)){
						store.dispatch({
							type: ALERT,
							message:`Your login session has expired. For security reasons, you will be logged out automatically. Please log in again to resume.`,
							title: 'Session Expired',
							mode: 'ok'
						});
						const waitForLogoutTillTimeout = new Promise((resolve) => {setTimeout(resolve(), 10000);});
						await waitForLogoutTillTimeout;
					}
					store.dispatch({ type: ALERT, message: oRsp.error, title: 'Error', mode: 'error' });
					logoutUser();
				}else{
					store.dispatch({ type: ALERT, message: oRsp.error, title: 'Error', mode: 'error' });
				}
			}
			else if(oRET.hasOwnProperty('verr')) {
				let eMessage = '';
				for (const property in oRET.verr) {
					eMessage = `${property}: ${oRET.verr[property]}`;
				}
				store.dispatch({ type: ALERT, message: eMessage, title: 'Error', mode: 'error' });
			}

			return Promise.reject(oRET);
		}
	);
}
