import filter from 'lodash/filter';
import { removeFromArray } from 'utils';
import {
  FETCH_ATTESTATIONS_SUCCESS,
  SUBMIT_ATTESTATION_SUCCESS,
} from 'constants.js';

const initialState = {
    pending: [],
    history: [],
};

const filterPendingAttestations = (action) =>{
  const {attestations, userId} = action;
    return attestations.filter(item => {
        if (userId === item.inspector_id) {
            return item.status === 'pending';
        }
        else if (userId === item.attester_id) {
            return item.status === 'pending' || item.status === 'attest';
        }
        return false;
    });
}

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ATTESTATIONS_SUCCESS:
      return {
        ...state,
        pending: filterPendingAttestations(action),
        history: filter(action.attestations, (a) => a.status !== 'pending'),
      };
    case SUBMIT_ATTESTATION_SUCCESS: {
      const index = state.pending.map((a) => a.id).indexOf(action.attestation.id);
      return {
        ...state,
        pending: removeFromArray(state.pending, index),
        history: [...state.history, action.attestation],
      };
    }
    default:
      return state;
  }
};

