// @flow
import React  from 'react';
import LDSH   from 'lodash';
import { View, Text, ProgressBar } from 'react-native';
import { connect }                           from 'react-redux';
import styled                                from 'styled-components/native';
import * as IO5                              from 'react-icons/io5';
import { Attestation, User }             from 'types';
import { submitAttestation, fetchAttestation, rejectAttestation } from 'actions/attestation';
import { checkImages } from 'actions/inspection';
import { fetchConstants }                    from 'actions/session.js';
import { showAlert }                         from 'actions/alert';
import { fetchLoan }                         from 'actions/loan';
import { createEntry, updateEntry }          from 'actions/loan';
import { setNavButtons, backBtn }            from 'reducers/navbar.js';
import * as UTL                              from 'utils';
import colors                                from 'styles/colors.js';
import snip                                  from 'styles/snip.js';
import ImageItem                             from 'scenes/ShowInspection/components/ImageItem';
import SceneCommon from 'containers/SceneCommon';
import Dialog      from 'components/Dialog';
import OS          from 'utils/offlineStore.js';
import store       from 'store.js';
import { WAITING } from 'constants.js';
import { saveInspectionQuestions } from 'actions/questions'
import EvpAttestationCamera from 'scenes/EvpAttestationCamera';
import UploadDocuments from 'scenes/UploadDocuments';


export const InspectionInfo = styled(View)`
	padding:          0.5em 0.6em;
	border-width:     1px;
	border-color:     rgb(220,220,220);
	border-radius:    2px;
	background-color: #fff;
`;

export const ImageGrid = styled(View)`
	/* display: inline-block; */
	width:   100%;
	padding: 0px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
`;

export const ImageGridConstruction = styled(View)`
	width:   100%;
	min-height: calc(100vh - 350px);
	border-width:     1px;
	border-color:     rgb(220,220,220);
	border-radius:    2px;
	background-color: #fff;

	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 2em;
	padding: 2em;
`;

type Props = {
	currentUser:      User,
	showAlert:        Function,
	fetchAttestation:  Function,
	submitAttestation: Function,
	setNavButtons:    Function,
	fetchLoan:        Function,
	createEntry:      Function,
	updateEntry:      Function,
	checkImages:      Function,
	inspEntries:      Array<Object>,
};

type State = {
	attestation:          Attestation,
	nReqDone:            number,
	nReq:                number,
	ptypes:              Array<Object>,
	activePage:          string,
	photoOpts:           Object,
	inspClsToPhotoType:  Object,
	inspClsLabel:        Array,
	selectedPercentage:  Object,
	oldAllocation:       number,
	allocationItems:     Array<Object>,
	selectedLineItem:    Object,
	finalSubmitDialog:   boolean,
	addMorePhotosDialog: boolean,
	imageSyncDialog:     boolean,
	maxPhotoCountSnap:   number,
	nPtypeDone:			 Number,
};

const Txt = (props) => {
	const propsNew = { ...props, style: { fontSize: 'inherit', ...props.style } };
	return <Text {...propsNew} />;
};

var interval;

class ShowAttestation extends React.Component {

	props: Props;
	state: State;

	constructor(props) {

		super(props);

		this.refFileInputDocument = React.createRef();

		this.state = {
			attestation:          null,
			nReqDone:            0,
			nReq:                0,
			ptypes:              [],
			inspClsLabel:        [],
			inspClsToPhotoType:  {},
			activePage:          'ShowAttestation',
			photoOpts:           {},
			selectedPercentage:  {},
			oldAllocation:       0,
			allocationItems:     [],
			selectedLineItem:    {},
			finalSubmitDialog:   false,
			addMorePhotosDialog: false,
			imageSyncDialog:     false,
			maxPhotoCountSnap: 40,
		};
	}

	navBack = () => {
		const { history, isUserFromHistoryTab} = this.props;
		let correctRoute = isUserFromHistoryTab ? '/history' : '/attestations';
		history.push(correctRoute);
	};

	componentWillUnmount() {
		this.props.setNavButtons(null);
		clearInterval(interval);
	}

	componentDidMount() {
		this.initialize();
		interval = setInterval(() => {
			const reload = localStorage.getItem('reloadInspection');
			if(reload === 'true' && this.state.activePage === 'ShowAttestation') {
				localStorage.setItem('reloadInspection', 'false');
				this.initialize();
			}
		}, 1000);
	}

	initialize = () => {
		fetchConstants()
			.then(async (consts) => {

				const { inspClsToPhotoType = {}, inspClsLabel = [], maxPhotoCountSnap = 40 } = consts;
				const { currentUser, match, fetchAttestation, setNavButtons } = this.props;
				const insp_id = parseInt(match.params.id);

				setNavButtons([backBtn(this.navBack, 'Home')]);

				fetchAttestation(currentUser.id, insp_id)
					.then((attestation) => {

						// CALC & CACHE REQ COUNTS
						const ptypes     = UTL.getByInspCls(inspClsToPhotoType, attestation.inspection.insp_cls);
						const ptypes_req = UTL.filterByReqd(ptypes);
						const nReq       = ptypes_req.length;
						const nReqDone   = UTL.getReqdFulfilledCt(ptypes_req, attestation.images);
						const bComplete  = (nReq === nReqDone);
						const nPtypeDone  = UTL.getReqdFulfilledCt(ptypes, attestation.images)

						// CONDITIONALLY SHOW 'SUBMIT' BUTTON
						let isAttester = attestation.attester_id === currentUser.id;
						if(isAttester && attestation.status === 'pending'){
							this.props.setNavButtons([
								backBtn(this.navBack,'Home')
							])
						}
						else if( bComplete ) {
							this.props.setNavButtons([
								backBtn(this.navBack,'Home'),
								{ label: 'Continue', action: this.handleDocumentsUpload }
							])
						}

						this.setState({ attestation, nReq, nReqDone, ptypes, inspClsToPhotoType, inspClsLabel, maxPhotoCountSnap, nPtypeDone });
					
						var loan = attestation.loan;
						const inspImages  = LDSH.get(attestation, 'images', []);
						if(inspImages.length >= maxPhotoCountSnap && ((loan.type !== 'Construction' && ptypes.length === nPtypeDone) || loan.type === 'Construction')){
							UTL.scrollToBottom();
						}
					});
			});
	}

	handleDocumentsUpload = () =>{
		this.setState({ activePage: 'UploadDocumentsPage'});
	}

	handleComplete = async() => {

		const { currentUser, submitAttestation, checkImages, showAlert, history } = this.props;
		const { attestation, nReq, nReqDone } = this.state;
		let isAttesterSubmitting = attestation.status === 'attest' && (attestation.attester_id === currentUser.id)

		OS.All('pendingAPI')
		.then(async (requests) => {
			var count = requests.filter(r => r.api === "uploadAttestationImage")
			.filter(r2 => r2.data.attestation.id === attestation.id).length;

			if(count === 0 || !UTL.isOnline()) {
				store.dispatch({ type: WAITING, bWait: false });
				await new Promise(res => setTimeout(res, 1000));
				if( LDSH.isObjectLike(attestation) && (nReq === nReqDone) ) {
					if(UTL.isOnline()) {
						checkImages(currentUser.id, attestation.inspection.id)
						.then((rsp) => {
							if(rsp.image_uploaded === true) {
								return submitAttestation(currentUser.id, attestation.id, isAttesterSubmitting, attestation.inspection.id)
									.then(() => {
										history.push('/attestations');
									});
							}
						});
					} else {
						return submitAttestation(currentUser.id, attestation.id, isAttesterSubmitting, attestation.inspection.id)
							.then(() => {
								history.push('/attestations');
							});
					}
				}
				else {
					showAlert('Please add each photo type', 'Incomplete', 'verr');
				}
			} else {
				this.handleImageSyncDialog();
			}
		});
	};

	handleReject = (rejectNote) =>{
		const { currentUser, rejectAttestation, showAlert, history } = this.props;
		const { attestation } = this.state;
		if(LDSH.isObjectLike(attestation)){
			return rejectAttestation(currentUser.id, attestation.id, rejectNote)
				.then(() => {
					history.push('/attestations');
				});
		}
		else{
			showAlert('There is a problem rejecting the attestation', 'Error', 'error')
		}
	}


	fnShowFinalSubmitDialog = () => {
		const { currentUser, checkImages } = this.props;
		const { attestation } = this.state;

		OS.All('pendingAPI')
		.then(async (requests) => {
			var count = requests.filter(r => r.api === "uploadImage")
			.filter(r2 => r2.data.attestation.id === attestation.id).length;

			if(count === 0 || !UTL.isOnline()) {
				store.dispatch({ type: WAITING, bWait: false });
				if(UTL.isOnline()) {
					checkImages(currentUser.id, attestation.id)
					.then((rsp) => {
						if(rsp.image_uploaded === true) {
							this.setState({ finalSubmitDialog: true });
						}
					});
				} else {
					this.setState({ finalSubmitDialog: true });	
				}
			} else {
				this.handleImageSyncDialog();
			}
		});
	}

	handleImageSyncDialog = (callback=null) => {
		store.dispatch({ type: WAITING, bWait: true });
		this.setState({ imageSyncDialog: false });
		if(callback) {
			callback();
		} else {
			setTimeout(() => {
				this.setState({ imageSyncDialog: true });
				store.dispatch({ type: WAITING, bWait: false });
			}, 30000);
		}
	}

	navigateToImage = (image) => {

		const { id } = image;
		const { attestation } = this.state;
		this.props.history.push(`/showAttestationImage/${attestation.id}/${id}`);
	};

	navigateToCamera = (photoOpts) => {

		const { attestation, maxPhotoCountSnap, ptypes, nPtypeDone } = this.state;

		const images  = LDSH.get(attestation, 'images', []);
		if(images.length >= maxPhotoCountSnap && ((attestation.loan.type !== 'Construction' && ptypes.length === nPtypeDone) || attestation.loan.type === 'Construction')){
			UTL.scrollToBottom();
			return
		}
		this.setState({ photoOpts, activePage: 'EvpCamera' });
		UTL.scrollToTop();
	};

	render() {

		const { attestation, nReqDone, nReq, ptypes, inspClsLabel,
			activePage, photoOpts, imageSyncDialog, maxPhotoCountSnap, nPtypeDone } = this.state;
		const {  history, currentUser } = this.props;

		if( !LDSH.isObjectLike(attestation) )
			return null;

		const isStr = (x) => ((typeof x === 'string') && (x.trim().length > 0));
		const { loan = null, organization = null } = attestation;

		const images  = LDSH.get(attestation, 'images', []);
		const vmargin = snip.pad;

		var ptypes2 = ptypes.map((PO) => PO.type.replace(' ', '_'));
		var max_key = Math.max(...images.map((img) => img.key));
		var image_key = Number(max_key < 100 ? 100 : max_key + 1);

		var isConstructionLoan = attestation.loan.type === 'Construction'
		var maxPhotoUploaded = isConstructionLoan ? images.length >= maxPhotoCountSnap : images.length + (ptypes.length - nPtypeDone) >= maxPhotoCountSnap
		var isAttester = attestation.attester_id === currentUser.id;

		// const formattedName = (name) =>{ return name.replace(/([^\w ]|_)/g, '').trim().split(" ").join("_");}

		return (
			<React.Fragment>
				<Dialog
					visible={imageSyncDialog}
					message={`Large images can take a while to transfer. Try submitting your attestation again in a few minutes.`}
					onAccept={() => this.handleImageSyncDialog(attestation.loan.type === 'Construction' ? this.fnShowFinalSubmitDialog : this.handleDocumentsUpload)}
				/>
				{activePage === 'ShowAttestation' &&
					<SceneCommon style={{ padding: vmargin }}>
						<InspectionInfo style={{ marginBottom: vmargin }}>
							{ organization &&
								<Text style={{ fontSize: '1.4em', fontWeight: 'bold' }}>{organization.name}</Text>
							}
							<View style={{ flexDirection: 'row', marginBottom: '1em' }}>
								<Text style={{ color: 'rgb(130,145,160)' }}>
									{attestation.due_at && UTL.toYMD(attestation.due_at)}
									{attestation.loan.type !== 'Construction' && (
										<React.Fragment>
											{ " - " }
											{ UTL.transformInspCls(inspClsLabel, attestation) }
										</React.Fragment>
									)}
								</Text>
							</View>
							{ loan &&
								<View>
									{ isStr(loan.address)     && <Txt>{loan.address}</Txt> }
									<Txt>{loan.city}, {loan.state} {loan.zip}</Txt>
								</View>
							}
							{attestation.instructions &&
								<View style={{ fontSize: '0.9em', marginTop: '1em' }}>
									<Text style={{ fontWeight: 'bold' }}>Instructions</Text>
									<Text>{attestation.instructions}</Text>
								</View>
							}
						</InspectionInfo>
						{(attestation.inspection.insp_cls === 'IntOpt') &&
							<Text style={{
								...snip.bdrAll,
								fontStyle:       'italic',
								color:           '#444',
								backgroundColor: 'white',
								padding:         '0.5em',
								marginBottom:    vmargin,
							}}>
								NOTE: Federal lending standards require only exterior photographs of your property.
								However, by also taking interior photographs, you greatly increase the accuracy of the requested valuation report.
								These photographs are confidential and will only be used in your evaluation report.
							</Text>
						}
						{(attestation.status === 'pending') && attestation.loan.type !== 'Construction' &&
							<View style={{ marginBottom: vmargin, paddingHorizontal: '0.2em' }}>
								<View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: '0.3rem' }}>
									{ (nReqDone === nReq) && <IO5.IoCheckbox color={colors.green} size="1.1em" style={{ marginRight: '0.25em' }} /> }
									<Text>{nReqDone} of {nReq} required photos complete</Text>
								</View>
								<ProgressBar
									indeterminate={false}
									progress={UTL.imagesCompleteRatio(nReq, nReqDone)}
								/>
							</View>
						}
						{
							(
								<ImageGrid>
								{
									ptypes.map((PO) => {

										const image = LDSH.find(images, { type: PO.type.replace(' ', '_') });

										return (
											<ImageItem key={PO.key} image={image} photoOpts={PO}
												onNavigateToImage={this.navigateToImage}
												onNavigateToCamera={this.navigateToCamera}
												inspectionSubmitted={attestation.status !== 'pending'|| isAttester}
											/>
										);
									})
								}
								{
									images
									.filter((i) => !ptypes2.includes(i.type))
									.map((img) => {
										return (
											<ImageItem key={img.key} image={img}
												photoOpts={{
													color: "rgb(128,128,128)",
													instructions: "Take more photos of the property",
													key: img.key,
													required: false,
													type: img.type
												}}
												onNavigateToImage={this.navigateToImage}
												onNavigateToCamera={this.navigateToCamera}
												inspectionSubmitted={attestation.status !== 'pending'||isAttester}
											/>
										);
									})
								}
								{
								!maxPhotoUploaded && (
								<ImageItem key={image_key} image={null} 
									photoOpts={{
										color: "rgb(128,128,128)",
										instructions: "Take more photos of the property.",
										key: image_key,
										required: false,
										type: "More"
									}}
									onNavigateToImage={this.navigateToImage}
									onNavigateToCamera={this.navigateToCamera}
									inspectionSubmitted={attestation.status !== 'pending'|| isAttester}
								/>)
								}
								</ImageGrid>
							)
						}
						{
							maxPhotoUploaded &&(
							<View style={{ margin:'0.5em' }}>
								<View style={{backgroundColor:'rgb(42,227,162)', borderRadius:'5px', padding:'10px'}}>
									<Text style={{ color: 'rgb(255,255,255)', fontWeight:'bold', fontSize:'15px' }}>
										{`You have reached the max number of photos.`}
									</Text>
								</View>
							</View>
							)
						}
					</SceneCommon>
				}
				{activePage === 'UploadDocumentsPage' &&
				 <UploadDocuments 
				 	history={history}
					attestation={attestation}
					isAttesterReviewMode={isAttester}
					handleSubmit={this.handleComplete}
					handleReject={this.handleReject}
					onChangePage={(page) => { 
						this.setState({activePage: page});
						if(page === 'ShowAttestation')
							this.initialize();
					}}
				 />
				}
				{activePage === 'EvpCamera' &&
					<EvpAttestationCamera
						history={history}
						attestation={attestation}
						photoOpts={photoOpts}
						onChangePage={(page) => { 
							this.setState({activePage: page});
							if(page === 'ShowAttestation')
								this.initialize();
						}}
					/>
				}
			</React.Fragment>
		);
	}
}

export default connect(
	(state) => ({
		currentUser: state.session.currentUser,
		inspEntries: state.loan.inspEntries,
		isUserFromHistoryTab: state.inspection.userVisitedHistory,
	}),
	{
		showAlert,
		fetchAttestation,
		submitAttestation,
		rejectAttestation,
		setNavButtons,
		fetchLoan,
		createEntry,
		updateEntry,
		saveInspectionQuestions,
		checkImages,
	}
)(ShowAttestation);
