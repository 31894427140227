// @flow
import React, { Component } from 'react';
import { Button, View } from 'react-native';
import colors from 'styles/colors.js';
import snip from 'styles/snip.js';
import styled from 'styled-components/native';

const BtnWrap = styled(View)`
	flex: 1 1 45%;
`;

type Props = {
	visible: boolean,
	onSubmit: Function,
	onRequestClose: Function,
	onUpdate: Function,
	comment: Object,
	edit: Boolean
};

type State = {
	body: string,
	imageComment: Object,
	editComment: Boolean
};

class CommentModal extends Component {

	state: State;
	props: Props;

	constructor(props: Props) {

		super(props);

		this.refText = React.createRef();
		this.state = {
			body: props.comment ? props.comment.body : "",
			imageComment: props.comment,
			editComment: props.edit,
		};



	}

	componentDidUpdate(prevProps, prevState) {

		if ((this.props.visible === true) && (prevState.visible !== true))
			this.refText.current.focus();

		if (this.props.comment !== prevProps.comment) {
			this.setState({ body: this.props.comment ? this.props.comment.body : '' });
		}

	}


	handleSubmit = () => {

		const { body } = this.state;
		const { onSubmit, onRequestClose, onUpdate, edit, comment } = this.props;
		let newCmt = comment;
		if (body.length) {
			if (edit) {
				newCmt.body = body
				onUpdate(newCmt)
					.then(() => { 
						onRequestClose();
					 })
					.catch(() => false);
			} else {
				onSubmit(body)
					.then(() => { 
						this.setState({body:""})
						onRequestClose();
					 })
					.catch(() => false);
			}


		} else {
			onRequestClose();
		}
	};

	render() {
		const { body } = this.state;
		return (
			<View style={{
				display: this.props.visible ? 'flex' : 'none',
				...snip.bdrAll,
				boxShadow: snip.boxShad,
				backgroundColor: 'white',
				margin: snip.pad,
				padding: snip.pad,
				position: 'absolute',
				top: '0px',
				left: '0px',
				right: '0px',
				zIndex: '500',
			}}>
				<textarea
					ref={this.refText}
					placeholder="enter comments here..."
					style={{ ...snip.bdrAll, minHeight: '7em', marginBottom: snip.pad, padding: '0.5em', resize: 'none' }}
					value={body}
					onChange={(evt) => {
						this.setState({ body: evt.target.value })
						evt.persist();
					}}
				/>
				<View style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}>
					<BtnWrap><Button title="Cancel" color={colors.red} 
						onPress={ ()=> {
							this.props.onRequestClose();
							this.setState({ body: "" });
							}} /></BtnWrap>
					<BtnWrap style={{ marginLeft: snip.pad }}><Button title="Save" color={colors.blue} 
					onPress={() => {
						this.handleSubmit(); 
						this.setState({ body: "" });
					}} /></BtnWrap>
				</View>
			</View>
		);
	}
}

export default CommentModal;
