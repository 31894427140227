// @flow
import LDSH from 'lodash';
import React, { Component } from 'react';
import { Redirect, BrowserRouter, Switch, Route } from 'react-router-dom';
import { View, Text } from 'react-native';
import { connect } from 'react-redux';
import { hideAlert } from 'actions/alert';
import { DBG_BUILD } from 'config/index';
import colors from 'styles/colors';
import Navbar            from 'components/Navbar';
import Alert             from 'containers/Alert';
// import Signup            from 'scenes/Signup';
import Waiting           from 'scenes/Waiting';
import Welcome           from 'scenes/Welcome';
import Login             from 'scenes/Login';
import EvpForgotPassword from 'scenes/EvpForgotPassword';
import ShowInspection    from 'scenes/ShowInspection';
import ShowAttestation   from 'scenes/ShowAttestation';
import ShowImage         from 'scenes/ShowImage';
import ShowAttestationImage from 'scenes/ShowAttestationImage';
import EvpCamera         from 'scenes/EvpCamera';
import EvpTabBar         from 'containers/EvpTabBar';
import NavbarButton      from 'components/NavbarButton';
import HamburgerMenu     from 'components/HamburgerMenu';
import AutoLogout		 from 'components/AutoLogout';
// import LogoImage from 'scenes/Welcome/EVP_Inhabet_Inspect_Logo_Modified.png'
import LogoImage from 'scenes/Welcome/WAIVIT-Inspect-Lockup-Blue-and-white.png';

type Props = {
	currentUser:   ?Object,
	navbarButtons: Array,
	bWait:         boolean,
	waitMsg:       string,
	bAlert:        boolean,
	alertTitle:    string,
	alertMessage:  string,
	alertMode:     string,
	hideAlert:     Function,
};

type State = {
	isUploading: boolean,
};

const wrapComponent = (JSXComponent, path, bAuthReqd = false) => {

	return {
		path,
		main: (props, vm) => {

			if( (bAuthReqd === true) && (vm.props.currentUser === null) ) {

				return <Redirect to={{
					pathname: '/login',
					state: { from: props.location }
				}} />;
			}

			return <JSXComponent {...props} />;
		},
	};
};

const arRoutes = [
	{ ...wrapComponent(Welcome, '/'), exact: true },
	wrapComponent(EvpCamera, "/snap/:insp_id/:ptype/:pkey", true),
	wrapComponent(EvpCamera, "/snap/:insp_id", true),
	wrapComponent(EvpTabBar, ["/inspect","/attestations", "/history", "/profile"], true),
	// wrapComponent(Signup, "/signup"),
	wrapComponent(Login, "/login"),
	wrapComponent(EvpForgotPassword, "/forgotPassword"),
	wrapComponent(ShowInspection, "/showInspection/:id", true),
	wrapComponent(ShowAttestation, "/showAttestation/:id", true),
	wrapComponent(ShowImage, "/showImage/:insp_id/:img_id", true),
	wrapComponent(ShowAttestationImage, "/showAttestationImage/:attest_id/:img_id", true)
];

class Root extends Component {

	props: Props;
	state: State;

	constructor(props) {

		super(props);

		this.state = {
			isUploading: false,
		}
	}

	componentDidMount() {
		setInterval(() => {
			if(localStorage.getItem('isUploading') === 'true')
				this.setState({ isUploading: true });
			else
				this.setState({ isUploading: false });
		}, 1000);
	}

	render() {

		const { navbarButtons, bWait, bAlert, waitMsg, currentUser } = this.props;
		const clsTitle = {
			fontWeight: 'bold',
			fontSize:   '1.1rem',
			color:'white'
		};

		const fWelcome = (props) => (props.location.pathname === '/');

		return (
			<View>
				{ DBG_BUILD &&
					<View style={{ backgroundColor: colors.red, padding: '0.2em', textAlign: 'right' }}>
						<Text style={{ color: 'white', fontWeight: 'bold' }}>DEVELOPMENT MODE</Text>
					</View>
				}
				{ bAlert &&
					<Alert
						title={this.props.alertTitle}
						message={this.props.alertMessage}
						mode={this.props.alertMode}
						hideAlert={this.props.hideAlert}
					/>
				}
				{ bWait && <Waiting waitMsg={waitMsg} /> }
				{ (currentUser !== null)&& <AutoLogout/>}
				<BrowserRouter>
					<Route children={(props) => {

						if( fWelcome(props) || (props.location.pathname.indexOf('/snap/') === 0) )
							return null;

						if(this.props.currentUser !== null)
							return (
								<HamburgerMenu logo={LogoImage}/>
							);

						if(this.props.currentUser === null)
							return (
								<HamburgerMenu logo={LogoImage} loginPage/>
							);

						return (
							<View style={{ backgroundColor:'black', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '2.4rem', paddingBottom:'6px', paddingTop:'6px' }}>
								<img src={LogoImage} alt="logo" style={{ height: '100%', width: 'auto' }}/>
							</View>
						);
					}}/>
					<Route children={(props) => {

						if( fWelcome(props) || (navbarButtons.length === 0) )
							return null;

						return (
							<Navbar style={{backgroundColor:'black'}}>
								{
									navbarButtons.map((btn, ix) => {

										if( btn === 'back' ) {

											return <NavbarButton key={ix} text="Back" iconName="ios-arrow-back" onPress={() => props.history.goBack()} />;
										}
										else if( typeof btn === 'string' ) {

											return <Text key={ix} style={clsTitle}>{btn}</Text>;
										}
										else if( LDSH.isObjectLike(btn) ) {
											if(btn.label === 'Submit' && this.state.isUploading) {
												return <NavbarButton key={ix} text={btn.label} onPress={btn.action} iconName={btn.iconName} class={'disabled'} disabled={true} />;
											} else {
												return <NavbarButton key={ix} text={btn.label} onPress={btn.action} iconName={btn.iconName} class={btn.class} disabled={btn.disabled} />;
											}
										}

										return null;
									})
								}
							</Navbar>
						);
					}} />
					<Switch>
						{arRoutes.map((route, ix) => (
							<Route
								key={ix}
								path={route.path}
								exact={route.exact}
								render={(props) => route.main(props, this)}
							/>
						))}
					</Switch>
				</BrowserRouter>
				{ this.state.isUploading &&
					<View style={{ backgroundColor: colors.orange, padding: '0.2em', position: 'fixed', bottom: '0' }}>
						<Text style={{ color: 'white', fontWeight: 'bold' }}>
							{/* Offline data sync is in progress. */}
							Waivit is uploading your photos. Please wait to submit your inspection.
						</Text>
					</View>
				}
			</View>
		);
	}
}

export default connect(
	(state) => ({
		currentUser:   state.session.currentUser,
		navbarButtons: state.navbar.buttons,
		bWait:         state.alert.bWait,
		waitMsg:       state.alert.waitMsg,
		bAlert:        state.alert.visible,
		alertTitle:    state.alert.title,
		alertMessage:  state.alert.message,
		alertMode:     state.alert.mode,
	}),
	{
		hideAlert,
	}
)(Root);

