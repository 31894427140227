import React, { Component } from 'react';
import { Modal, Button, View, Text } from 'react-native';
import styled from 'styled-components/native';
import colors from 'styles/colors';

const ModalOverlay = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContainer = styled(View)`
  width: 80%;
  background-color: #e6e6e6;
  padding: 10px;
  border-radius: 4px;
`;


const ButtonContainer = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`;

const ButtonWrap = styled(View)`
	flex: 0.48 0.48 12%;
`;

const AlertView = styled(View)`
    margin:1em;
`;

const AlertText = styled(Text)`
    font-size:18px;
`;

class FileDeleteAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  handleClose = (deleteDocument) => {
    const { onRequestClose } = this.props;
    onRequestClose({ proceedAndDelete: deleteDocument});
  };

  render() {
    const { visible } = this.props;

    return (
      <Modal
        transparent={true}
        visible={visible}
        onRequestClose={() => { this.handleClose(false); }}
      >
        <ModalOverlay>
          <ModalContainer>
            <AlertView>
                <AlertText>
                Are you sure you want to delete this document? If you click "Yes" below, the document will be permanently deleted.
                </AlertText>
            </AlertView>
            <ButtonContainer>
              <ButtonWrap>
                <Button title="No" onPress={() => { this.handleClose(false); }} color={`${colors.red}`} />
              </ButtonWrap>
              <ButtonWrap>
                <Button title="Yes" onPress={() => { this.handleClose(true); }} color={`${colors.evp_blue}`}/>
              </ButtonWrap>
            </ButtonContainer>
          </ModalContainer>
        </ModalOverlay>
      </Modal>
    );
  }
}

export default FileDeleteAlert;
