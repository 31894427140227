import React, { Component } from "react";
import LDSH   from 'lodash';
import {
  Container,
  QuestionContainer,
  QuestionTitle,
  InputContainer,
  TextInput,
  Picker,
  ButtonContainer,
  Button,
  ButtonText,
  NotificationView,
  NotificationText,
} from "./styles";
import { View } from "react-native";
import RadioButton from "./RadioButton";
import MultiSelectCheckbox from "./MultiSelectCheckbox";
import jsep from "jsep";

class Questionnaire extends Component {
  constructor(props) {
    super(props);
    this.state = {
      capturedAnswers: {},
    };
  }

  isVisible(visibleIf, title) {
    if (visibleIf === "") {
      return true;
    }

    const { capturedAnswers } = this.state;

    const deleteObjectByDesc = (searchStr) =>{
      for (const key in capturedAnswers) {
        if (capturedAnswers[key].desc === searchStr) {
          delete capturedAnswers[key];
          break;
        }
      }
    }

    const matches = visibleIf.match(/{([^}]+)}/g);
    if (matches) {
      for (const match of matches) {
        const name = match.substring(1, match.length - 1);
        let result = LDSH.find(capturedAnswers, o => o.desc === name);
        if (result !== undefined) {
          visibleIf = visibleIf.replace(match, `"${result.ans}"`);
        } else {
          deleteObjectByDesc(title);
          return false;
        }
      }
    }

    const ast = jsep(visibleIf);
    const evaluator = (ast) => {
      switch (ast.type) {
        case "Literal":
          return ast.value;
        case "Identifier":
          return capturedAnswers[ast.name];
        case "BinaryExpression":
          const left = evaluator(ast.left);
          const right = evaluator(ast.right);
          switch (ast.operator) {
            case "===":
              return left === right;
            case "!==":
              return left !== right;
            default:
              throw new Error(`Unknown operator: ${ast.operator}`);
          }
        default:
          throw new Error(`Unknown node type: ${ast.type}`);
      }
    };
    let result = evaluator(ast);
    if (!result) {
      deleteObjectByDesc(title);
    }
    return result;
  }

  handleAnswer = (question, value) => {

    const getParent = (obj, searchStr) =>{
      for (const key in obj) {
        if (obj[key].desc === searchStr) {
          return key;
        }
      }
    }
    let parent = "";
    let level = 0;

    if(question.visibleIf !== ""){
      let logic = question.visibleIf.match(/{([^}]+)}/g)[0];
      let logicString = logic.substring(1, logic.length - 1)
      parent = getParent(this.state.capturedAnswers,logicString);
    }

    if(parent!=="") {
      level = parseInt(this.state.capturedAnswers[parent].level) + 1
    }

    this.setState((prevState) => {
      const capturedAnswers = {
        ...prevState.capturedAnswers,
      };
      if ((value === "") || (Array.isArray(value) && value.length === 0)) {
        delete capturedAnswers[question.name];
      } else {
        capturedAnswers[question.name] = {
          "desc": question.title,
          "ans": value,
          "parent": parent.toString(),
          "level": level.toString(),
        };
      }
      return {
        capturedAnswers,
      };
    });
  };

  renderQuestion = (question) => {
    switch (question.type) {
      case "text":
        return (
          <QuestionContainer>
            <QuestionTitle>{question.title}</QuestionTitle>
            <InputContainer>
              <TextInput
                onChangeText={(text) => this.handleAnswer(question, text)}
              />
            </InputContainer>
          </QuestionContainer>
        );
      case "radio":
        return (
          <QuestionContainer>
            <QuestionTitle>{question.title}</QuestionTitle>
            <RadioButton
              options={question.choices}
              onClick={(data) => this.handleAnswer(question, data)}
            />
          </QuestionContainer>
        );
      case "checkbox":
        return (
          <QuestionContainer>
            <QuestionTitle>{question.title}</QuestionTitle>
            <MultiSelectCheckbox
              options={question.choices}
              onChange={(data) => this.handleAnswer(question, data)}
            />
          </QuestionContainer>
        );
      case "dropdown":
        return (
          <QuestionContainer>
            <QuestionTitle>{question.title}</QuestionTitle>
            <Picker
              selectedValue={
                this.state.capturedAnswers[question.name]
                  ? this.state.capturedAnswers[question.name].ans
                  : ""
              }
              onValueChange={(value) =>
                this.handleAnswer(question, value)
              }
            >
              <Picker.Item label="" value="" />
              {question.choices.map((choice, index) => (
                <Picker.Item key={index} label={choice} value={choice} />
              ))}
            </Picker>
          </QuestionContainer>
        );
      default:
        return null;
    }
  };

  render() {
    const { questions, questionInstruction, onSave } = this.props;
    const { capturedAnswers } = this.state;

    let visibleQuestions = questions.filter((question) => this.isVisible(question.visibleIf, question.title));
    let countVisibleQuestions = visibleQuestions.length;
    let answeredQuestions = Object.keys(capturedAnswers).length;
    let hasAnsweredAllQuestions = countVisibleQuestions === answeredQuestions;

    return (
      <Container>
        { (questions.length < 1) &&
          <NotificationView>
          <NotificationText>{'Currently, no questions have been set for this inspection.'}</NotificationText>
          </NotificationView>
        }
        <NotificationView>
          <NotificationText>{questionInstruction}</NotificationText>
        </NotificationView>
        {questions.map(
          (question, index) =>
            this.isVisible(question.visibleIf, question.title) && (
              <View key={index}>{this.renderQuestion(question)}</View>
            )
        )}
        <ButtonContainer>
          <Button
            disabled={!hasAnsweredAllQuestions}
            onPress={!hasAnsweredAllQuestions ? null :() => {
              onSave(capturedAnswers);
            }}
          >
            <ButtonText>{"Submit"}</ButtonText>
          </Button>
        </ButtonContainer>
      </Container>
    );
  }
}

export default Questionnaire;
