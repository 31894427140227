// @flow
import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import snip from 'styles/snip.js';

const Component = styled(View)`
	display:         flex;
	flex-direction:  row;
	flex-basis:      2.7em;
	justify-content: space-between;
	align-items:     stretch;

	padding:  ${snip.pad};
	overflow: none;

	background:          #fff;
  border-bottom-width: 0.5px;
  border-bottom-color: rgb(220,220,220);
`;

const Navbar = (props) => (<Component {...props} />);

export default Navbar;

