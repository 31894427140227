// @flow
import LDSH from 'lodash';
import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { connect } from 'react-redux';
import LS    from 'utils/localStore.js';
import { isOnline } from 'utils';
import * as IO5 from 'react-icons/io5';
import type { Image as ImageType, Inspection, User } from 'types';
import { setNavButtons, backBtn } from 'reducers/navbar.js';
import { deleteImage, createComment, fetchInspection, updateComment, deleteComment } from 'actions/inspection';
import colors        from 'styles/colors';
import snip          from 'styles/snip';
import LinkButton    from 'components/LinkButton';
import ImageComment  from './ImageComment';
import CommentModal  from './CommentModal';
import SceneCommon   from 'containers/SceneCommon';
import Dialog        from 'components/Dialog';

type Props = {
	history:         Object,
	currentUser:     User,
	setNavButtons:   Function,
	deleteImage:     Function,
	createComment:   Function,
	updateComment:	 Function,
	deleteComment:	 Function,
	fetchInspection: Function,
};

type State = {
	inspection: Inspection,
	image:      ImageType,
	commentModalOpen:    boolean,
	addMorePhotosDialog: boolean,
	deletePhotoDialog:   boolean,
	selectedLineItem:    Object,
	edit:				 boolean,
	comment:			 Object,
	commentToBeDeleted:  Object,
	deleteCommentId:	String,
	isDeleteCommentCompleted:Boolean
};

class ShowImage extends Component {

	state: State;
	props: Props;

	constructor(props: Props) {

		super(props);

		this.state = {
			inspection: null,
			image: null,
			commentModalOpen:    false,
			addMorePhotosDialog: false,
			deletePhotoDialog:   false,
			selectedLineItem:    {},
			isUploading: false,
			edit: false,
			comment: null,
			commentToBeDeleted:null,
			deleteCommentId:null,
			isDeleteCommentCompleted:false,
		};
	}

	backToInsp = () => {

		const { match, history } = this.props;
		const insp_id = parseInt(match.params.insp_id);
		history.push(`/showInspection/${insp_id}`);
	};

	backtoImg = ()=> {
		const { match, history } = this.props;
		const insp_id = parseInt(match.params.insp_id);
		const img_id = parseInt(match.params.img_id);
		history.push(`/showImage/${insp_id}/${img_id}`);
	}

	componentWillUnmount() {
		this.props.setNavButtons(null);
	}

	componentDidMount() {

		const { fetchInspection, currentUser, match, setNavButtons } = this.props;
		const insp_id = parseInt(match.params.insp_id);
		const img_id  = match.params.img_id;

		const noAction = () => false;
		const fSetNav = (title) => {
			setNavButtons([ backBtn(this.backToInsp, 'Photos'), {label : title,iconName: 'ios-map',action: noAction} ]);
		};

		

		setInterval(() => {
			if(localStorage.getItem('isUploading') === 'true'){
				this.setState({ isUploading: true });
			}
			else
				this.setState({ isUploading: false });
		}, 1000);

		fSetNav('');

		fetchInspection(currentUser.id, insp_id)
			.then((inspection) => {

				const arImages = LDSH.get(inspection, 'images', []);

				// eslint-disable-next-line
				let image = arImages.find((I) => (I.id == img_id));

				if( LDSH.isObjectLike(image) )
					fSetNav(`${image.type}`);
				else
					image = null;

				this.setState({ inspection, image });

				var lineItem = sessionStorage.getItem('SelectedLineItem');
				if(typeof lineItem === 'string') {
					lineItem = JSON.parse(lineItem);
					this.setState({ selectedLineItem: lineItem, addMorePhotosDialog: true });
				}
			});
	}

	handleCommentSubmit = (body) => {

		const { createComment }     = this.props;
		const { image, inspection } = this.state;

		return createComment(inspection.id, image.id, { body })
			.then((rsp) => {

				// SHIFT NEW COMMENT INTO IMAGE OBJECT
				let imgNew = LDSH.cloneDeep(image);
				imgNew.comments.splice(0, 0, rsp.data);
				this.setState({ image: imgNew });

				return Promise.resolve(rsp);
			});
	};

	handleEdit = (comment) => {
		this.setState({ comment: comment, edit:true, commentModalOpen: true })
	};

	updateComment = ()=>{
		const { updateComment }     = this.props;
		const { inspection, comment } = this.state;
		var oldNewCommentId = sessionStorage.getItem('OldNewCommentId');
		if(oldNewCommentId){
			if(isOnline()){
				oldNewCommentId = JSON.parse(oldNewCommentId)
				if(oldNewCommentId.old === comment.id){
					comment.id = oldNewCommentId.new;
				}
			}
		}
		this.setState({ commentModalOpen: false })

		return updateComment(inspection.id, comment)
				.then((rsp)=>{
					this.setState({comment:null, edit:false})
					this.backtoImg()
					// this.props.history.push(`/showImage/${parseInt(inspection.id)}/${parseInt(image.id)}`)
				}).catch(()=>false)
	}



	handleDelete = () => {

		const { deleteImage, currentUser } = this.props;
		const { image, inspection } = this.state;

		let imageId = image.id
		var newImageId;
		var oldNewImageId = sessionStorage.getItem('OldNewImageId');
		if(oldNewImageId) {
			oldNewImageId = JSON.parse(oldNewImageId);
			if(oldNewImageId.old === imageId) {
				newImageId = oldNewImageId.new;
				imageId = newImageId;
			}
		}

		return deleteImage(currentUser.id, inspection.id, imageId)
			.then(() => { this.backToInsp(); })
			.catch(() => false);
	};

	confirmDelete = () => {
		this.setState({ deletePhotoDialog: true });

		// let opt = window.confirm('Permanently delete this photo?');

		// if( opt === true )
		// 	this.handleDelete();
	};

	handleAddMorePhotos = (addMore) => {
		if(addMore) {
			const {inspection, /*selectedLineItem*/} = this.state;
			// this.props.history.push(`/snap/${inspection.id}/${selectedLineItem.name}/${selectedLineItem.id}`);
			this.props.history.push(`/showInspection/${inspection.id}`);
		} else {
			sessionStorage.removeItem('SelectedLineItem');
			this.setState({ addMorePhotosDialog: false });
			this.backToInsp();
		}
	}

	isValidDate = (number) => {
		var inputDate = new Date(number);
		var currentDate = new Date();
		var differenceInMilliseconds = Math.abs(currentDate - inputDate);
		var differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
	  
		return (differenceInDays <= 3 && differenceInDays >= 0)
	  }

	  handleCommentDelete = async (comment) => {
		const { image, inspection } = this.state;
	  
		const getCommentFromCache = (inspectionId, comment) => {
		  return LS.Get('inspections', inspectionId)
			.then((insp) => {
			  if (!insp) {
				throw new Error('deleteComment: inspection not found');
			  }
	  
			  let matchingComment;
	  
			  if (Array.isArray(insp.images)) {
				matchingComment = insp.images.flatMap((image) => image.comments).find((commentObj) => commentObj.body === comment.body);
			  }
	  
			  return matchingComment ? matchingComment.id : null;
			});
		};
	  
		const isTempImage = image && image.is_temp_image;
		const isOnlineAndTempImage = isOnline() && isTempImage;
	  
		let deleteCommentId = comment.id;
	  
		if (isOnlineAndTempImage) {
		  try {
			const matchingCommentId = await getCommentFromCache(inspection.id, comment);
			deleteCommentId = matchingCommentId ? matchingCommentId : deleteCommentId;
		  } catch (error) {
			console.error(error);
		  }
		}
	  
		this.setState(
		  {
			deleteCommentDialog: true,
			edit: false,
			commentToBeDeleted: comment,
			deleteCommentId: deleteCommentId,
			isDeleteCommentCompleted: false, 
		  },
		  () => {
			this.setState({ isDeleteCommentCompleted: true });
		  }
		);
	  };
	  
	  deleteComment = () => {
		const { image, commentToBeDeleted, inspection, deleteCommentId, isDeleteCommentCompleted } = this.state;
		const { deleteComment } = this.props;
		let imageId = commentToBeDeleted.assoc_id;
	  
		if (isOnline() && (this.isValidDate(imageId) || this.isValidDate(deleteCommentId))) {
			this.setState({ deleteCommentDialog: false})
			return;
		}
	  
		if (isDeleteCommentCompleted) { 
		  return deleteComment(commentToBeDeleted.user.id, inspection.id, imageId, deleteCommentId)
			.then((rsp) => {
			  let imgNew = LDSH.cloneDeep(image);
			  imgNew.comments.splice(image.comments.indexOf(commentToBeDeleted), 1);
			  this.setState({ image: imgNew, deleteCommentDialog: false, comment: null, commentToBeDeleted: null, edit: false, isDeleteCommentCompleted: false });
			  return Promise.resolve(rsp);
			});
		}
	  };

	render() {
		const { image, inspection, addMorePhotosDialog, deletePhotoDialog, selectedLineItem, isUploading, comment, edit, deleteCommentDialog } = this.state;
		if( !image || !inspection )
			return null;

		const comments = LDSH.get(image, 'comments', []);
		const styleBtnTxt = { fontSize: '1.3rem', marginLeft: snip.pad };
		const styleBtn = { flexDirection: 'row' };
		const iconSize = '1.4em';

		return (
			<SceneCommon>
				<Dialog
					visible={addMorePhotosDialog}
					message={`Do you need to add more ${selectedLineItem.name} photos?`}
					onAccept={() => this.handleAddMorePhotos(true)}
					onReject={() => this.handleAddMorePhotos(false)}
					confirm={true}
				/>
				<Dialog
					visible={deletePhotoDialog}
					message={`Permanently delete this photo?`}
					onAccept={() => this.handleDelete()}
					onReject={() => this.setState({ deletePhotoDialog: false })}
					confirm={true}
				/>
				{image &&
					<View>
						<img src={image.square} alt={image.type} style={{ width: '100%', height: 'auto' }} />
					</View>
				}
				{(inspection.status === 'pending') &&
				<LinkButton style={{...styleBtn, cursor: isUploading ? 'not-allowed' : 'pointer',
						pointerEvents: isUploading ? 'none' : 'auto' }} onPress={() => this.setState({ commentModalOpen: true, edit:false, comment:null })} >
					<IO5.IoChatboxOutline color={colors.green_primary} size={iconSize} />
					<Text style={{...styleBtnTxt, color: colors.green_primary}}>Add Comment</Text>
				</LinkButton>
				}
				{(comments.length > 0) &&
					<View>
						<Text style={{ padding: snip.pad, textAlign: 'center', fontSize: '10pt' }}>COMMENTS</Text>
							<View>{comments.map((comment) => <ImageComment key={comment.id} handleEdit={this.handleEdit} handleDelete={this.handleCommentDelete}
							url={`/showImage/${parseInt(inspection.id)}/${parseInt(image.id)}`} comment={comment} isUploading={isUploading} pendingInspection={(inspection.status === 'pending')}/>)}
							</View>
					</View>
				}
				{(inspection.status === 'pending') &&
					<LinkButton color="red" style={{ 
						...styleBtn, marginTop: '7vh', 
						marginBottom: '7vh', 
						cursor: isUploading ? 'not-allowed' : 'pointer',
						pointerEvents: isUploading ? 'none' : 'auto'
						 }} onPress={this.confirmDelete}>
						<IO5.IoClose color={colors.red} size={iconSize} />
						<Text style={{...styleBtnTxt, color: colors.red}}>Delete Photo</Text>
					</LinkButton>
				}
				<CommentModal
					visible={this.state.commentModalOpen}
					onSubmit={this.handleCommentSubmit}
					onUpdate={this.updateComment}
					comment={comment}
					edit = {edit}
					onRequestClose={() => this.setState({ commentModalOpen: false, edit:false, comment:null })}
				/>
				<Dialog
					visible={deleteCommentDialog}
					message={`Permanently delete this comment?`}
					onAccept={() => this.deleteComment()}
					onReject={() => {this.setState({ deleteCommentDialog: false, comment:null })}}
					confirm={true}
				/>
			</SceneCommon>
		);
	}
}

export default connect(
	(state) => ({
		currentUser: state.session.currentUser,
	}),
	{
		deleteImage,
		createComment,
		updateComment,
		deleteComment,
		fetchInspection,
		setNavButtons,
	}
)(ShowImage);
