// @flow
import React from 'react';
import LDSH  from 'lodash';
import { View, Text, TouchableOpacity } from 'react-native';
import { Image as ImageType } from 'types.js';
import * as IO5 from 'react-icons/io5';

type Props = {
	photoOpts:           Object,
	image:               ?ImageType,
	onNavigateToImage:   Function,
	onNavigateToCamera:  Function,
	inspectionSubmitted: boolean,
};

const ImageItem = ({
	image,
	photoOpts,
	onNavigateToImage,
	onNavigateToCamera,
	inspectionSubmitted,
}: Props) => {

	const styleTop = {
		position:  'relative',
		float:     'left',
		boxSizing: 'border-box',
		// width:     '50%',
		height:    '46vw',
	};

	const styleEmptySlot = {
		borderRadius:   '4px',

		position: 'absolute',
		top:      '0px',
		bottom:   '0.5rem',
		left:     '0.25rem',
		right:    '0.25rem',

		display:        'flex',
		alignItems:     'center',
		justifyContent: 'center',
		padding:        '10px',
	};

	const styleComment = {
		position:   'absolute',
		bottom:     '0.5rem',
		right:      '0.5rem',
		display:    'inline-block',
		color:      'white',
		fontSize:   '1em',
	};

	const styleText = {
		fontSize:   '110%',
		lineHeight: '115%',
		color:      'inherit',
		textShadow: '0px 0px 4px black',
	};

	if( LDSH.isObjectLike(image) ) {

		const { thumbnail, comments } = image;

		return (
			<TouchableOpacity style={styleTop} onPress={() => onNavigateToImage(image)}>
				<View style={{ ...styleEmptySlot, backgroundImage: `url("${thumbnail}")`, backgroundSize: 'cover' }}>
					<View style={{ ...styleComment, left: '0.5rem', bottom: '0.4rem', right: 'auto' }}>
						<Text style={{ ...styleText, fontSize: '8pt', lineHeight: 'normal', maxWidth:'90%', overflowWrap:'anywhere' }}>{image.type}</Text>
					</View>
					{(comments.length > 0) &&
						<View style={styleComment}>
							<IO5.IoChatbubble style={{ marginRight: '0.4em' }} />
							<Text style={styleText}>{comments.length}</Text>
						</View>
					}
				</View>
			</TouchableOpacity>
		);
	}

	if( !inspectionSubmitted ) {

		const { color, type, required } = photoOpts;

		return (
			<TouchableOpacity style={styleTop} onPress={() => onNavigateToCamera(photoOpts)}>
				<View style={{ ...styleEmptySlot, backgroundColor: color }}>
					<Text
						style={{
							fontWeight:   'bold',
							textAlign:    'center',
							marginBottom: '0.25em',
							color:        '#fff',
						}}
					>
						{type === 'More' ? 'Add' : ''} {type} {type === 'More' ? 'Photos' : 'Photo'}
					</Text>
					<Text style={{ color: '#fff' }}>
						({required ? 'required' : 'optional'})
					</Text>
				</View>
			</TouchableOpacity>
		);
	}

	return null;
};

export default ImageItem;
