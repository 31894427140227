// @flow
import React from 'react';
import {
  Text,
  View,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import styled from 'styled-components/native';
import colors from 'styles/colors';

const Component = styled(TouchableOpacity)`
  flex-direction:   row;
  align-items:      center;
  justify-content:  center;
  border-radius:    2px;
  padding:          10px 0px;
  background-color: ${(props) => (props.color ? colors[props.color] : 'rgb(220,220,220)')};
`;

const ButtonText = styled(Text)`
  font-weight:  bold;
  font-size:    12pt;
  color:        #fff;
`;

type Props = {
  children?: any,
  animating?: boolean,
};

const Button = ({ children, animating, ...props }: Props) =>
  <Component {...props}>
    <View>
      {!animating &&
        <ButtonText>
          {children}
        </ButtonText>
      }
      {animating && <ActivityIndicator animating={animating} color="#fff" />}
    </View>
  </Component>;

Button.defaultProps = {
  children: undefined,
  animating: false,
};

export default Button;
