import React, { useState, useEffect } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import colors from "styles/colors";
import * as Bi from "react-icons/io5";

const CustomCheckbox = (props) => {
  const [isChecked, setIsChecked] = useState(props.checked);

  useEffect(() => {
    setIsChecked(props.checked);
  }, [props.checked]);

  const handlePress = () => {
    setIsChecked(!isChecked);
    props.onPress && props.onPress({userConsent : !isChecked});
  };

  return (
    <View style={{backgroundColor:'#d4ddff', borderRadius:4}}>
    <TouchableOpacity
      onPress={handlePress}
      style={{
        flexDirection: "row",
        alignItems: "flex-start",
        marginVertical: 10,
        marginBottom:10,
        marginLeft:8,
        flexShrink: 1, 
        flexWrap: "wrap" 
      }}
    >
      <View style={{ flexDirection: "row", alignItems: "flex-start",flexShrink: 1, flexWrap: "wrap"}}>
        <View
          style={{
            height: 25,
            width: 25,
            borderWidth: 2,
            borderColor: isChecked ? `${colors.evp_blue}` : "grey",
            alignItems: "center",
            justifyContent: "center",
            marginRight: 12,
            padding:0,
          }}
        >
          {isChecked && (
            <Bi.IoCheckboxSharp color={colors.evp_blue} size={25} style={{ margin: 0 }} />
          )}
        </View>
        <Text style={{ fontSize: 17, flex: 1, }}>{props.label}</Text>
      </View>
    </TouchableOpacity>
    </View>
  );
};

export default CustomCheckbox;
