import LDSH   from 'lodash';
import {
    FETCH_LOAN_SUCCESS,
    FETCH_INSP_WITH_ENTRIES_SUCCESS,
    CHAN_ENTRY_CREATED,
    CHAN_ENTRY_UPDATED,
} from 'constants.js';

const initialState = {
    currentLoan: {},
    lineItems:   [],
    inspections: [],
    inspEntries: [],
};

// eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_LOAN_SUCCESS: {
            action.loan.line_items.forEach((item, index) => {
                action.loan.line_items[index].allocation = 0;
            });
            return {
                ...state,
                currentLoan: action.loan,
                lineItems: action.loan.line_items,
            }
        }
        case FETCH_INSP_WITH_ENTRIES_SUCCESS: {
            let entries = [];
            action.inspections.forEach((insp) => {
                if(insp.entries)
                entries = entries.concat(insp.entries);
            });

            let lineItems = state.lineItems;
            lineItems.forEach((item, index) => {
                const arEntries = LDSH.filter(entries, { loan_line_item_id: item.id });
		        var allocation = arEntries.reduce((total, entry) => { return(total + Number(entry.allocation)) }, 0);
                lineItems[index].allocation = allocation;
            });
            
            return {
                ...state,
                inspections: action.inspections,
                inspEntries: entries,
                lineItems: lineItems,
            }
        }
        case CHAN_ENTRY_CREATED: {
            let _entries = Promise.resolve(state.inspEntries);
            _entries.then((entries) => {
                entries = entries.push(action.entry);
                return {
                    ...state,
                    inspEntries: entries,
                }
            });
            return { ...state }
        }
        case CHAN_ENTRY_UPDATED: {
            let _entries = Promise.resolve(state.inspEntries);
            _entries.then((entries) => {
                var index = entries.findIndex(entry => {
                    return entry.id === action.entry.id;
                });
                entries[index] = action.entry;
                return {
                    ...state,
                    inspEntries: entries,
                }
            });
            return { ...state }
        }
        default:
            return state;
    }
};

