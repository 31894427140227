import React from 'react';
import { View, Text }     from 'react-native';
import { containImgDims } from 'utils/index.js';
import snip               from 'styles/snip.js';

type Props = {
	fnSubmitCanvas:  Function,
	fnUpdateNavbar:  Function,
	fnGetGeo:        Function,
	fnErrAlert:      Function, // (title, msg)
};

class CamWebRTC extends React.Component {

	props: Props;

	constructor(props) {

		super(props);

		this.hResizeTimeout = null;

		this.refContainer = React.createRef();
		this.refCanvas    = React.createRef();
		this.refVideo     = React.createRef();

		this.state = {
			winX: 0,
			winY: 0,
			bInitialized: false,
			bRecording:   false,
			canvWidth:    0,
			canvHeight:   0,
		};
	}

	updateNavbar = (bRecord) => {

		this.props.fnUpdateNavbar(
			bRecord,
			this.takePhoto,
			(evt) => this.props.fnSubmitCanvas(this.refCanvas.current)
		);
	};

	onContainerResize = (evt) => {

		if( this.hResizeTimeout != null ) {

			window.clearTimeout(this.hResizeTimeout);
			this.hResizeTimeout = null;
		}

		// NOTE: debounce window resizing on iOS to get around video redraw issues
		this.hResizeTimeout = window.setTimeout(() => {

			const oVid = this.refVideo.current;
			const oCtr = this.refContainer.current.parentElement;

			if( !oVid || !oCtr )
				return;

			const { dx, dy } = containImgDims(
				oCtr.clientWidth,
				oCtr.clientHeight,
				oVid.videoWidth,
				oVid.videoHeight
			);

			const newState = { winX: dx, winY: dy, bInitialized: true };

			// console.log('CONTAINER', { dx: oCtr.clientWidth, dy: oCtr.clientHeight });
			// console.log('VIDEO', { dx: oVid.videoWidth, dy: oVid.videoHeight });
			// console.log('FIT', newState);

			this.setState(newState);
			this.hResizeTimeout = null;

		}, 100);
	};

	playPause = (bPlay) => {

		let dVid = this.refVideo.current;
		if( dVid ) {

			if( bPlay )
				dVid.play();
			else
				dVid.pause();
		}
		else
			bPlay = false;

		this.setState({ bRecording: bPlay });
		this.updateNavbar(bPlay);
	};

	componentDidMount() {

		this.updateNavbar(true);

		try {

			let dVid = this.refVideo.current;

			if( !dVid )
				return;

			dVid.setAttribute('autoplay', '');
			dVid.setAttribute('muted', '');
			dVid.setAttribute('playsinline', '');

			// GET VIDEO CAPTURE DEVICE, BIND TO <video> TAG
			window.navigator.mediaDevices.getUserMedia({ audio: false, video: { facingMode: 'environment', width: 1920 } })
				.then((mediaStream) => {

					dVid.srcObject = mediaStream;
					this.playPause(true);
				})
				.catch((err) => {

					console.error(err);
					const msg = JSON.stringify(err, null, '  ');
					this.props.fnErrAlert('User Media Error', msg);
				});

			// UPDATE VIDEO/CANVAS DIMENSIONS WHEN
			// SOURCE OR WINDOW SIZE CHANGES
			dVid.addEventListener('canplay', this.onContainerResize);
			window.addEventListener('resize', this.onContainerResize);
			window.addEventListener('orientationchange', this.onContainerResize);
		}
		catch(e) {

			this.props.fnErrAlert(e.name, e.message);
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.onContainerResize);
		window.removeEventListener('orientationchange', this.onContainerResize);
	}

	// CALLED FROM NAVBAR BUTTONS
	takePhoto = (evt) => {

		if( this.state.bRecording === true ) {

			// GET GEOLOCATION BEFORE CAPTURE, PROCEED REGARDLESS
			// (should have at least one good point since app init)
			this.props.fnGetGeo()
				.finally(() => {

					let dVid  = this.refVideo.current;
					let oCanv = this.refCanvas.current;

					if( !dVid || !oCanv )
						return;

					this.playPause(false);
					if(oCanv !== null){
						this.setState(
							{ canvWidth: dVid.videoWidth, canvHeight: dVid.videoHeight },
							() => { oCanv.getContext('2d').drawImage(dVid, 0, 0); }
						);
					}
				});
		}
		else {

			// RESTART VIDEO STREAM
			this.playPause(true);
		}
	};

	render() {

		const { winX, winY, bInitialized, bRecording, canvWidth, canvHeight } = this.state;

		const styleImg = {
			width:  winX,
			height: winY,
			margin: '0px auto',
		};

		const styleMsg = {
			fontSize: '1.1em',
			fontFamily: 'monospace',
			padding: snip.pad,
			marginTop: '15vh',
			textAlign: 'center',
		};

		const fDisplay = (bShow) => (bShow ? 'block' : 'none');

		return (
			<View ref={this.refContainer}>
				{ !bInitialized && <Text style={styleMsg}>Connecting to camera...</Text> }
				<video  ref={this.refVideo}  style={{ ...styleImg, display: fDisplay(bRecording) }} />
				<canvas ref={this.refCanvas} style={{ ...styleImg, display: fDisplay(!bRecording) }} width={canvWidth} height={canvHeight} />
			</View>
		);
	}
}

export default CamWebRTC;

