import React from 'react';
import { View, Text } from 'react-native';
import styled from 'styled-components/native';
import colors from 'styles/colors.js';
import snip   from 'styles/snip.js';

const Component = styled(View)`
  flex-direction:      column;
  align-items:         stretch;
  border-top-width:    1px;
	border-top-color:    ${(props) => colors.border};
  background-color:    #fff;
	padding: 0.5em 0.25em;
`;

const Label = styled(Text)`
  flex:        1;
  align-items: center;
	font-weight: bold;
	padding:     0.5em;
`;

class Input extends React.Component {

	render() {

		const { style, label, verr } = this.props;
		const bHasLabel = (typeof label === 'string') && (label.length > 0);
		const bHasVerr  = (typeof verr === 'string') && (verr.length > 0);

		let inputProps = Object.assign({}, this.props);
		delete inputProps.style;
		delete inputProps.label;

		if( typeof inputProps.onChangeText === 'function' ) {

			inputProps.onChange = (evt) => {
				this.props.onChangeText(evt.target.value);
			};

			delete inputProps.onChangeText;
		}

		if( label === "Image Description" ){
			let userEntered = inputProps.value;
			const regex = /^[ A-Za-z0-9_/(s|:,&)-]*$/
			if (!userEntered.match(regex) && userEntered.length > 0) {
				inputProps.value = userEntered.slice(0, -1);
			}
		}
		// style, label, placeholder, value, defaultValue, onChange
		return (
			<Component style={style}>
				<View style={{ flexDirection: 'row', alignItems: 'center' }}>
					{ bHasLabel && <Label>{label}</Label> }
					<input style={{ ...snip.bdrAll, flex: 2, minHeight: '1.5em', padding: '0.3em 0.5em'}} {...inputProps} />
				</View>
				{ bHasVerr && 
					<View style={{ textAlign: 'right' }}>
						<Text style={{ fontSize: '0.8em', color: 'red', paddingTop: '0.5em' }}>{verr}</Text>
					</View>
				}
			</Component>
		);
	}
}

export default Input;
