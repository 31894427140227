import React, { useState } from "react";
import { View, Text, TouchableOpacity } from "react-native";

const CustomCheckbox = (props) => {
  const [isChecked, setIsChecked] = useState(false);

  const handlePress = () => {
    setIsChecked(!isChecked);
    props.onPress && props.onPress(!isChecked, props.label);
  };

  return (
    <TouchableOpacity
      onPress={handlePress}
      style={{
        flexDirection: "row",
        alignItems: "center",
        marginVertical: 10,
      }}
    >
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <View
          style={{
            height: 24,
            width: 24,
            borderWidth: 2,
            borderColor: isChecked ? "#2ae3a2" : "grey",
            alignItems: "center",
            justifyContent: "center",
            marginRight: 12,
          }}
        >
          {isChecked && (
            <View
              style={{
                height: 12,
                width: 12,
                backgroundColor: "#2ae3a2",
              }}
            />
          )}
        </View>
        <Text>{props.label}</Text>
      </View>
    </TouchableOpacity>
  );
};

export default function MultiSelectCheckbox(props) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handlePress = (isChecked, label) => {
    let newSelectedOptions = [...selectedOptions];
    if (isChecked) {
      newSelectedOptions.push(label);
    } else {
      newSelectedOptions = newSelectedOptions.filter(
        (option) => option !== label
      );
    }
    setSelectedOptions(newSelectedOptions);
    props.onChange && props.onChange(newSelectedOptions);
  };

  return (
    <View>
      {props.options.map((option, index) => (
        <CustomCheckbox
          key={`option-${index}`}
          label={option}
          checked={selectedOptions.includes(option)}
          onPress={handlePress}
        />
      ))}
    </View>
  );
}
