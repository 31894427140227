import axios from 'axios';
import LS    from 'utils/localStore.js';
import OS    from 'utils/offlineStore.js';
import { dynamicSort, isOnline } from 'utils';
import { showAlert }   from 'actions/alert';
import { FETCH_INSPECTIONS_SUCCESS, SUBMIT_INSPECTION_SUCCESS } from 'constants.js';
import LDSH   from 'lodash';

// TODO: manually report localstore errors (not handled by axios)
// TODO: prevent double-error with default axios reporter

function saveDirtyImages() {

	return LS.All('inspections')
		.then((arInsp) => {

			let dirtyInsps = [];

			if( Array.isArray(arInsp) )
				dirtyInsps = arInsp.filter((I) => (I.is_dirty === true));

			if( dirtyInsps.length === 0 )
				return Promise.resolve(true);

			let arUploadPromise = [];

			// ATTEMPT TO UPLOAD ANY UNSAVED IMAGES
			dirtyInsps.forEach((insp) => {
				insp.images.forEach((img) => {

					if( img.is_temp_image === true ) {
						arUploadPromise.push(
							uploadImageBase(
								img.user,
								insp,
								img.square,
								img.type,
								img.key,
								img.latitude,
								img.longitude,
								img.id
							)
						);
					}

				});
			});

			return Promise.allSettled(arUploadPromise);
		});
}

export function getCachedInsps(dispatch) {

	return LS.All('inspections')
		.then((arInsps) => {

			const inspections = Array.isArray(arInsps) ? arInsps : [];
			inspections.sort(dynamicSort('due_at', 'asc'));
			dispatch({ type: FETCH_INSPECTIONS_SUCCESS, inspections });
			return Promise.resolve({ data: inspections });
		});
}

export function fetchInspections(userId, bForceAjax = false) {

	return (dispatch) => {
		if(isOnline()) {
			// SAVE ANY DIRTY BEFORE REPLACING THE OLD INSPECTION/IMAGE CACHE
			return saveDirtyImages()
				.then(() => axios.get(`/users/${userId}/inspections?data_for_offline=true`))
				.then((rsp) => {

					OS.Store('inspections').clear()
					.then((cleared) => OS.BulkPut('inspections', rsp.data));

					// CLEAR & RE-INPUT INSPECTIONS INTO CACHE
					return LS.Store('inspections').clear()
						.then((cleared) => LS.BulkPut('inspections', rsp.data))
						.then((lastId) => {
							dispatch({ type: FETCH_INSPECTIONS_SUCCESS, inspections: rsp.data });

							return Promise.resolve(rsp);
						});
				})
				.catch((err) => {

					if( bForceAjax === true )
						return Promise.reject(err);
					else
						return getCachedInsps(dispatch)
				});
		} else {
			return OS.All('inspections')
			.then((rsp) => {
				rsp.sort(dynamicSort('due_at', 'asc'));
				return LS.Store('inspections').clear()
				.then((cleared) => LS.BulkPut('inspections', rsp))
				.then((lastId) => {
					dispatch({ type: FETCH_INSPECTIONS_SUCCESS, inspections: rsp });
					return Promise.resolve({ data:rsp });
				});
			})
		}
	};
}

export function fetchInspection(userId, id) {

	userId = parseInt(userId);
	id = parseInt(id);

	return (dispatch) => {

		return LS.Store('inspections')
			.filter((V) => ((V.id === id) && (V.inspector_id === userId)))
			.toArray((ret) => {

				if( Array.isArray(ret) && (ret.length > 0) )
					return Promise.resolve(ret[0]);

				showAlert(
					'Press OK to continue.',
					'Inspection Not Found',
					'err'
				)(dispatch);

				// TODO: in UI, go back to list when inspection not found
				return Promise.reject({ message: 'inspection not found' });
			});
	};
}

export function checkImages(userId, inspectionId) {
	return (dispatch) => {
		return axios.get(`/users/${userId}/inspections/${inspectionId}/check_images`)
		.then((rsp) => {
			return Promise.resolve(rsp);
		});
	}
}

export function submitInspection(userId, inspectionId) {

	userId       = parseInt(userId);
	inspectionId = parseInt(inspectionId);

	if(isOnline()) {
		return (dispatch) => {
			return saveDirtyImages()
				.then(() => axios.patch(`/users/${userId}/inspections/${inspectionId}/submit`,  {isSubmittedOffline:false}))
				.then((rsp) => {

					return LS.Get('inspections', inspectionId)
						.then((oldInsp) => {

							// OVERWRITE CACHED INSPECTION WITH RETURNED DATA,
							// PRESERVE CACHED IMAGE DATA
							const newInsp = oldInsp ? Object.assign({}, oldInsp, rsp.data, { images: oldInsp.images }) : rsp.data;
							return LS.Put('inspections', newInsp).then(() => Promise.resolve(newInsp));
						});
				})
				.then((newInsp) => {

					dispatch({ type: SUBMIT_INSPECTION_SUCCESS, inspection: newInsp });

					showAlert(
						'Press OK to continue.',
						'Inspection Submitted',
						'ok'
					)(dispatch);

					return Promise.resolve(newInsp);
				});
		};
	} else {
		return (dispatch) => {
            var obj = {
				id: Date.now(),
				api: 'submitInspection',
				url: `/users/${userId}/inspections/${inspectionId}/submit`,
				data: { inspection: { id: inspectionId} }
			}
			OS.Put('pendingAPI', obj);
			return LS.Get('inspections', inspectionId)
			.then((oldInsp) => {
				oldInsp.status = 'review';
				
				OS.Put('inspections', { ...oldInsp });
				
				return LS.Put('inspections', oldInsp).then(() => Promise.resolve(oldInsp));
			})
			.then((newInsp) => {
				dispatch({ type: SUBMIT_INSPECTION_SUCCESS, inspection: newInsp });

				showAlert(
					'Press OK to continue.',
					'Inspection Submitted',
					'ok'
				)(dispatch);

				return Promise.resolve(newInsp);
			});
        }
	}
}

function saveImageLocal(params) {

	const { filename, user, inspection, blob, type, key, latitude, longitude, offline } = params;
	const now   = new Date();
	const szNow = now.toISOString();

	return new Promise((fnOK, fnERR) => {

		let rdr = new FileReader();
		rdr.onload = (evt) => {

			let tmpId = new Uint8Array(8);
			window.crypto.getRandomValues(tmpId);

			// CREATE TEMPORARY IMAGE RECORD
			const imgNew = {
				// id:              tmpId.join('.'),
				id:              Date.now(),
				user_id:         user.id,
				inspection_id:   inspection.id,
				organization_id: inspection.organization_id,
				user,
				type,
				key,
				latitude,
				longitude,
				file:          filename,
				comments:      [],
				inserted_at:   szNow,
				updated_at:    szNow,
				notes:         null,
				square:        evt.target.result,
				thumbnail:     evt.target.result,
				is_temp_image: true,
			};

			// ADD TO INSPECTION CACHE
			LS.Get('inspections', inspection.id)
				.then((insp) => {

					if( !insp ) {
						fnERR({ message: 'saveImageLocal: inspection not found' });
						return;
					}

					if( Array.isArray(insp.images) )
						insp.images.push(imgNew);
					else
						insp.images = [imgNew];
					if(offline) {
						LS.Put('inspections', { ...insp })
							.then(() => fnOK(imgNew));
					} else {
						LS.Put('inspections', { ...insp, is_dirty: true })
							.then(() => fnOK(imgNew));
					}
				})
				.catch((err) => fnERR(err));
		};
		rdr.readAsDataURL(blob);

	});
}

function dataURItoBlob(dataURI) {

	const ixComma    = dataURI.indexOf(',');
	const prefix     = dataURI.substring(0, ixComma);
  const byteString = atob(dataURI.substring(ixComma+1));

	// separate out the mime component
	const mimeString = prefix.split(':')[1].split(';')[0]

	// write the bytes of the string to an ArrayBuffer
	let ab = new ArrayBuffer(byteString.length);

	// create a view into the buffer
	let ia = new Uint8Array(ab);

	// set the bytes of the buffer to the correct values
	for(let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	// write the ArrayBuffer to a blob, and you're done
	return new Blob([ab], {type: mimeString});
}

function uploadImageBase(user, inspection, blob, type, key, latitude, longitude, tempId = null, imageId = null) {

	const inspection_id = parseInt(inspection.id);
	const user_id       = parseInt(user.id);
	const filename      = `${type}_${key}.jpg`;

	const data = new FormData();

	if( typeof blob === 'string' )
		blob = dataURItoBlob(blob);

	data.append('image[file]', blob, filename);
	data.append('image[type]', type);
	data.append('image[key]', key);
	data.append('image[latitude]', latitude);
	data.append('image[longitude]', longitude);
	data.append('image[image_id]', imageId);

	return axios.post(`/users/${user_id}/inspections/${inspection_id}/images`, data)
		.then((rsp) => {

			// ADD TO INSPECTION CACHE
			return LS.Get('inspections', inspection_id)
				.then((insp) => {

					if( !insp )
						return Promise.reject({ message: 'uploadImage: inspection not found' });

					if( Array.isArray(insp.images) ) {

						// REMOVE CORRESPONDING TEMP IMAGE, IF SPECIFIED
						if( tempId !== null )
							insp.images = insp.images.filter((I) => (I.id !== tempId));

						insp.images.push(rsp.data);
					}
					else
						insp.images = [rsp.data];

					return OS.Put('inspections', insp)
					.then(() => {
						return LS.Put('inspections', insp).then(() => Promise.resolve(rsp));
					});
				});
		})
		.catch((err) => {

			console.error('SAVE LOCAL', err);
			throw err;

			// return saveImageLocal(
			// 	{ filename, user, inspection, blob, type, key, latitude, longitude }
			// )
			// 	.then((imgNew) => {
			// 		return Promise.resolve({ data: imgNew });
			// 	});
		});
}


export function uploadPendingImage(user, inspection, blob, type, key, latitude, longitude, tempId = null, imageId = null) {
	return uploadImageBase(user, inspection, blob, type, key, latitude, longitude, tempId, imageId);
}

export function uploadImage(user, inspection, blob, type, key, latitude, longitude, tempId = null) {
	// if(isOnline()) {
	// 	return (dispatch) => {
	// 		return saveDirtyImages()
	// 			.then(() => uploadImageBase(user, inspection, blob, type, key, latitude, longitude, tempId));
	// 	};
	// } else {
		return (dispatch) => {
			var obj = {
				id: Date.now(),
				api: 'uploadImage',
				data: {
					user,
					inspection,
					blob,
					type,
					key,
					latitude,
					longitude,
					tempId
				}
			}
	
			var filename = obj.id + '.jpg';
			return saveImageLocal(
				{ filename, user, inspection, blob, type, key, latitude, longitude, offline: true }
			)
			.then((imgNew) => {
				obj.id = imgNew.id;
				obj.data.tempId = imgNew.id;
				OS.Get('inspections', inspection.id)
				.then((insp) => {
					let rdr = new FileReader();
					rdr.onload = (evt) => {
						if( Array.isArray(insp.images) )
							insp.images.push(imgNew);
						else
							insp.images = [imgNew];
						
						OS.Put('inspections', { ...insp })
					}
					rdr.readAsDataURL(blob);
				})
				OS.Put('pendingAPI', obj);
				return Promise.resolve({ data: { ...imgNew } });
			});
		};
	// }
}

export function deleteComment(userId, inspectionId, imageId, commentId){

	inspectionId = parseInt(inspectionId);

	return (dispatch) => {

		return LS.GetImage(inspectionId, imageId)
			.then((img) => {
					if(isOnline()) {
						axios.delete(`/users/${userId}/images/${imageId}/comment/${commentId}`)
						.then(resp => {return Promise.resolve(resp)})
						.catch(err => {return Promise.reject(err)});
					} else {
						OS.All('pendingAPI')
							.then((pendingAPIArray)=>{
								let createCommentObj = pendingAPIArray.find(api => api.id === commentId);
								if(createCommentObj){
									OS.Delete('pendingAPI', createCommentObj.id);
								}
								else {
									var obj = {
										id: Date.now(),
										api: 'deleteComment',
										url: `/users/${userId}/images/${imageId}/comment/${commentId}`,
									}
									OS.Put('pendingAPI', obj);
								}

						})
					}
			})
			.then((rsp) => {

				// REMOVE FROM INSPECTION CACHE
				return LS.Get('inspections', inspectionId)
					.then((insp) => {

						if( !insp )
							return Promise.reject({ message: 'deleteComment: inspection not found' });
						
						// DELETE COMMENT
						if( Array.isArray(insp.images) ){
							const imageForComment = LDSH.filter(insp.images, { id: imageId });							
							imageForComment[0].comments = imageForComment[0].comments.filter((I) => ( I.id !== commentId ));
						}

						OS.Put('inspections', { ...insp });

						return LS.Put('inspections', insp).then(() => Promise.resolve(rsp));
					});
			});
	};


}

export function deleteImage(userId, inspectionId, imageId) {

	inspectionId = parseInt(inspectionId);

	return (dispatch) => {

		return LS.GetImage(inspectionId, imageId)
			.then((img) => {

				// SKIP AJAX ON TEMP IMAGES
				if( img && (img.is_temp_image === true) ) {
					OS.Delete('pendingAPI', imageId);
					return Promise.resolve({ data: img });
				}
				else {
					if(isOnline()) {
						return axios.delete(`/users/${userId}/inspections/${inspectionId}/images/${imageId}`);
					} else {
						var obj = {
							id: Date.now(),
							api: 'deleteImage',
							url: `/users/${userId}/inspections/${inspectionId}/images/${imageId}`,
						}
						OS.Put('pendingAPI', obj);
						return Promise.resolve({ data: img });
					}
				}
			})
			.then((rsp) => {

				// REMOVE FROM INSPECTION CACHE
				return LS.Get('inspections', inspectionId)
					.then((insp) => {

						if( !insp )
							return Promise.reject({ message: 'deleteImage: inspection not found' });

						// DELETE IMAGE
						if( Array.isArray(insp.images) )
							insp.images = insp.images.filter((I) => ( I.id !== imageId ));
						else
							insp.images = [];

						OS.Put('inspections', { ...insp });

						return LS.Put('inspections', insp).then(() => Promise.resolve(rsp));
					});
			});
	};
}

export function updateComment(inspectionId, comment){

	inspectionId = parseInt(inspectionId);
	let imageId = comment.assoc_id;

	return (dispatch) => {

		return LS.GetImage(inspectionId, imageId)
			.then((img) => {

					if(isOnline()) {
						return axios.patch(`/inspections/${inspectionId}/images/${comment.assoc_id}/comments/${comment.id}`, { comment })
					} else {
					OS.All('pendingAPI')
						.then((pendingAPIArray)=>{
							let createCommentObj = pendingAPIArray.find(api => api.id === comment.id);
							if(createCommentObj){
								createCommentObj.data.comment.body = comment.body;
								OS.Delete('pendingAPI', createCommentObj.id);
								OS.Put('pendingAPI', createCommentObj);
							}
							else {
								var updateObj = {
									id: Date.now(),
									api: 'updateComment',
									url: `/inspections/${inspectionId}/images/${comment.assoc_id}/comments/${comment.id}`,
									data: { comment },
								}
								OS.Put('pendingAPI', updateObj);
							}

						})
						return Promise.resolve({ data: img });
					}
			})
			.then((rsp) => {

				// UPDATE FROM INSPECTION CACHE
				return LS.Get('inspections', inspectionId)
					.then((insp) => {

						if( !insp )
							return Promise.reject({ message: 'deleteComment: inspection not found' });
						
						// UPDATE COMMENT
						if( Array.isArray(insp.images) ){
							const imageForComment = LDSH.filter(insp.images, { id: imageId });
							const index = imageForComment[0].comments.findIndex(obj => obj.id === comment.id);
							if (index !== -1) {
								imageForComment[0].comments[index] = comment;
							}
						}

						OS.Put('inspections', { ...insp });

						return LS.Put('inspections', insp).then(() => Promise.resolve(rsp));
					});
			});
	};
}

export function createComment(inspectionId, imageId, data) {

	inspectionId = parseInt(inspectionId);

	return (dispatch) => {
		return LS.GetImage(inspectionId, imageId)
		.then((_img) => {

			if(isOnline() && (_img && !_img.is_temp_image)) {
				return axios.post(`/inspections/${inspectionId}/images/${imageId}/comments`, { comment: data })
				.then((rsp) => {
	
					// ADD TO INSPECTION CACHE
					return LS.Get('inspections', inspectionId)
						.then((insp) => {
	
							if( !insp )
								return Promise.reject({ message: 'createComment: inspection not found' });
	
							let img;
							if( Array.isArray(insp.images) )
								img = insp.images.find((IMG) => (IMG.id === imageId));
	
							if( !img )
								return Promise.reject({ message: 'createComment: image not found' });
	
							if( Array.isArray(img.comments) )
								img.comments.push(rsp.data);
							else
								img.comments = [rsp.data];
	
							return LS.Put('inspections', insp).then(() => Promise.resolve(rsp));
						});
				});
			} else {
				var newImageId;
				var oldNewImageId = sessionStorage.getItem('OldNewImageId');
				if(oldNewImageId) {
					oldNewImageId = JSON.parse(oldNewImageId);
					if(oldNewImageId.old === imageId) {
						newImageId = oldNewImageId.new;
						imageId = newImageId;
					}
				}
				var obj = {
					id: Date.now(),
					api: 'addComment',
					url: `/inspections/${inspectionId}/images/${imageId}/comments`,
					data: { comment: data },
					tempImgId: null,
				}
				OS.Put('pendingAPI', obj);
	
				// ADD TO INSPECTION CACHE
				return OS.Get('inspections', inspectionId)
				.then((insp) => {
	
					if( !insp )
						return Promise.reject({ message: 'createComment: inspection not found' });
	
					let img;
					if( Array.isArray(insp.images) )
						img = insp.images.find((IMG) => (IMG.id === imageId));
	
					if(!newImageId) {
						if( !img )
							return Promise.reject({ message: 'createComment: image not found' });
		
						if(img.is_temp_image) {
							obj.tempImgId = img.id;
							OS.Put('pendingAPI', obj);
						}
					}
	
					return LS.Get('auth', 1)
					.then((authRet) => {
						return authRet.data;
					})
					.then((user) => {
						return {
							data: {
								id: obj.id,
								assoc_id: imageId,
								user_id: user.id,
								...data,
								user: user,
								inserted_at: new Date(),
								updated_at: new Date(),
							}
						}
					})
					.then((rsp) => {
						if(newImageId) {
							return Promise.resolve(rsp);
						} else {
							if( Array.isArray(img.comments) )
								img.comments.push(rsp.data);
							else
								img.comments = [rsp.data];
							LS.Put('inspections', insp);
							return OS.Put('inspections', insp).then(() => Promise.resolve(rsp));
						}
					})
					.catch(err => Promise.reject(err));
				});
			}
		});
	};
}
