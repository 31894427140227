// @flow
import React from 'react';
import { connect }           from 'react-redux';
import { View } from 'react-native';
import { Attestation, User }  from 'types';
import { fetchAttestations }  from 'actions/attestation';
import { uploadOfflineData2 } from 'actions/pending';
import AttestationList        from 'components/AttestationList';
import NoResults             from 'components/NoResults';
import Dialog                from 'components/Dialog';
import LS        from 'utils/localStore.js';
import OS        from 'utils/offlineStore.js';
import { isOnline } from 'utils';
import { setNavButtons }            from 'reducers/navbar.js';

type Props = {
	currentUser: User,
	arAttestations:      Array<Attestation>,
	fetchAttestations: Function,
};

type State = {
	uploading: Boolean,
}

class Attestations extends React.Component {

	props: Props;
	state: State;

	constructor(props) {

		super(props);

		this.state = {
			uploading: false,
		};
	}

	componentWillUnmount(){
		this.props.setNavButtons(null)
	}

	componentDidMount() {
		this.props.setNavButtons(null)
		this.handleRefresh();
		this.isOfflineDataSyncing();
	}

	syncOfflineData = () => {
		setInterval(() => {
			LS.Get('auth', 1)
			.then((authRet) => {
				if(authRet) {
					OS.All('pendingAPI')
					.then((requests) => {
						if(isOnline() && requests.length > 0 && localStorage.getItem('isUploading') === 'false') {
							localStorage.setItem('isUploading', 'true');
							this.setState({ uploading: true });
							uploadOfflineData2();
						} else {
							this.setState({ uploading: false });
						}
					});
				} else {
					this.setState({ uploading: false });
				}
			})
			.catch(err => Promise.reject(err));
		}, 5000);
	}

	isOfflineDataSyncing = () => {
		setInterval(() => {
			if(localStorage.getItem('isUploading') === 'true') {
				this.setState({ uploading: true });
			} else {
				this.setState({ uploading: false });
			}
		}, 5000);
	}

	handleRefresh = () => {
		return this.props.fetchAttestations(this.props.currentUser.id);
	};

	render() {

		const { arAttestations } = this.props;
		const { uploading } = this.state;

		return (
			<View>
				<Dialog
					visible={uploading}
					message={`The data sync is in progress. Please wait...`}
					uploading
				/>
				{(arAttestations.length === 0) &&
					<NoResults label="No Results">
						Home attestation requests will appear here in your queue
					</NoResults>
				}
				{(arAttestations.length > 0) && <AttestationList {...this.props} attestations={arAttestations}/>}
			</View>
		);
	}
}

export default connect(
	(state) => ({
		arAttestations:      state.attestation.pending,
		currentUser: state.session.currentUser,
	}),
	{
		fetchAttestations,
		setNavButtons
	}
)(Attestations);

