// @flow
import React, { Component } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import snip   from 'styles/snip.js';

const Shadow = styled(View)`
	z-index:  200;
	position: fixed;
	top:      0px;
	bottom:   0px;
	width:    100%;
	padding:  1rem;

	display:  flex;
	flex-direction:  column;
	justify-content: center;
	align-items:     stretch;

	margin:           0px;
	background-color: rgba(0, 0, 0, 0.5);
`;

const AlertView = styled(View)`
	flex-direction:  column;
	justify-content: space-between;
	text-align: center;
	border:           ${props => snip.bdr};
	border-radius:    10px;
	background-color: white;
	width: fit-content;
	margin: auto;
`;

const Txt = styled(Text)`
	padding:   2rem 1rem;
	font-size: inherit;
`;

const BtnTxt = styled(Text)`
	padding:   0.7rem;
	font-size: inherit;
	color:     rgb(0, 122, 255);
`;

type Props = {
	message:  string,
	onAccept: Function,
	onReject: Function,
	visible:  Boolean,
	confirm:  Boolean,
	uploading: Boolean,
};

class Alert extends Component {

	props: Props;

	render() {

		const { message, onAccept, onReject, visible, confirm, uploading } = this.props;

		return (
			<React.Fragment>
				{visible &&
					<Shadow>
						<AlertView>
							<View>
								<Txt style={{ borderBottom: snip.bdr }}>
									{message}
								</Txt>
								
								{!uploading && (
									<React.Fragment>
										<TouchableOpacity
											onPress={() => onAccept()}
											style={{ borderBottom: confirm ? snip.bdr : '' }}
										>
											<BtnTxt>{confirm ? 'Yes' : 'OK'}</BtnTxt>
										</TouchableOpacity>
										{confirm &&
											<TouchableOpacity
												onPress={() => onReject()}
											>
												<BtnTxt>No</BtnTxt>
											</TouchableOpacity>
										}
									</React.Fragment>
								)}
							</View>
						</AlertView>
					</Shadow>
				}
			</React.Fragment>
		);
	}
}

export default Alert;

