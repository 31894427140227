import {
    INSPECTION_QUESTIONS_SAVED
} from 'constants.js';


const initialState = {
    questions: {},
};

// eslint-disable-next-line
export default function(state = initialState, action){
    switch(action.type){
        case INSPECTION_QUESTIONS_SAVED:
            return state;
        default:
            return state;
    }
}