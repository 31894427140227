import LDSH from 'lodash';
import colors from 'styles/colors';

export function mergeInArray(array, index, object) {
  if (index >= 0) {
    return [
      ...array.slice(0, index),
      object,
      ...array.slice(index + 1),
    ];
  }
  return array;
}

export function removeFromArray(array, index) {
  if (index >= 0) {
    return [
      ...array.slice(0, index),
      ...array.slice(index + 1),
    ];
  }
  return array;
}

/* eslint-disable no-bitwise, no-mixed-operators */
export const uuid = (a) =>
  (a ? (a ^ Math.random() * 16 >> a / 4).toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, uuid));

export function statusColor(status) {
  switch (status) {
    case 'pending':
      return colors.blue;
    case 'review':
      return colors.yellow;
    case 'completed':
      return colors.green;
    default:
      return colors.blue;
  }
}

export function toYMD(szDate) {

	const dt = new Date(szDate);

	if( dt ) {

		const parts = [dt.getMonth() + 1, dt.getDate(), dt.getFullYear()];
		return parts.join('/');
	}

	return '';
}

export function transformInspCls(inspClsLabel, oI) {

  const IC = oI.insp_cls;

  if( Array.isArray(inspClsLabel) ) {

    for(var i = 0; i < inspClsLabel.length; i++) {

      if( inspClsLabel[i][0] === IC )
        return inspClsLabel[i][1];
    }
  }

  return '';
}

export function imagesCompleteRatio(nReq, nReqDone) {

	if( nReq > 0 )
		return nReqDone / nReq;

	return 1.0;
}

export function getByInspCls(inspClsToPhotoType, insp_cls) {

	if( LDSH.isObjectLike(inspClsToPhotoType) && (typeof insp_cls === 'string') ) {

		if( Array.isArray(inspClsToPhotoType[insp_cls]) )
			return inspClsToPhotoType[insp_cls];
	}

	return [];
}

export function filterByReqd(photoTypes) {
	return LDSH.filter(photoTypes, { required: true });
}

export function getReqdFulfilledCt(photoTypesReq, images) {

	if( !Array.isArray(images) )
		return 0;

	let FULFILLED = 0;
	let REQ_MET = LDSH.reduce(photoTypesReq, (ACC, PT) => {
		ACC[PT.type.replace(' ', '_')] = false;
		return ACC;
	}, {});

	// ITERATE TAKEN PHOTOS
	images.forEach((oI) => {

		// CHECK IF IT IS A REQUIRED PHOTO
		if( REQ_MET.hasOwnProperty(oI.type) && (REQ_MET[oI.type] === false) ) {

			REQ_MET[oI.type] = true;
			FULFILLED += 1;
		}
	});

	return FULFILLED;
}

export function getReqdFulfilledCtConstruction(lineItems, images) {
	let FULFILLED = 0;
	const formattedName = (name) =>{ return name.replace(/([^\w ]|_)/g, '').trim().split(" ").join("_");}
	lineItems.forEach((li) => {
		if(images.find(img => img.type === formattedName(li.name)))
			FULFILLED += 1;
	});
	return FULFILLED;
}

// RETURNS { dx, dy } THAT FITS { dstX, dstY }
// WITH SAME ASPECT RATIO AS { srcX, srcY }
export function containImgDims(dstX, dstY, srcX, srcY) {

	const ERR = { dx: 0, dy: 0 };

	if( isNaN(dstX) || isNaN(dstY) || isNaN(srcX) || isNaN(srcY) )
		return ERR;

	const ratioDst = dstX / dstY;
	const ratioSrc = srcX / srcY;

	if( isNaN(ratioDst) || isNaN(ratioSrc) )
		return ERR;

	// PIN TO Y, RESIZE X
	if( ratioDst > ratioSrc ) {
		return { dx: (dstY * ratioSrc), dy: dstY };
	}
	// PIN TO X, RESIZE Y
	else {
		return { dx: dstX, dy: (dstX / ratioSrc) };
	}
}

export function dynamicSort(property, order) {
	var sort_order = 1;
	if (order === "desc") {
	  sort_order = -1;
	}
	return function (a, b) {
		// a should come before b in the sorted order
		if (a[property] < b[property]) {
			return -1 * sort_order;
		// a should come after b in the sorted order
		} else if (a[property] > b[property]) {
			return 1 * sort_order;
		// a and b are the same
		} else {
			return 0 * sort_order;
		}
	}
}

export function isOnline() {
	if(window.navigator.onLine)
		return true;
	return false;
}

export function isMobileDevice() {
	if(window.navigator.userAgent.match(/Android/i)
	|| window.navigator.userAgent.match(/iPhone/i)
	|| window.navigator.userAgent.match(/iPad/i)) {
		return true;
	}
	return false;
}


export function scrollToTop() {
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
}

export function scrollToBottom(){
	document.body.scrollTop = document.body.scrollHeight;
	document.documentElement.scrollTop = document.documentElement.scrollHeight;
}

export function findHost(hostname){
	const devw = "dev";
	const testw = "test";
	const localhostw= "localhost";
	if (hostname.includes(devw)) {
	  return 'dev';
	} else if (hostname.includes(testw)) {
	  return 'test';
	}else if (hostname.includes(localhostw)){
		return 'localhost';
	} 
	return 'prod';
}