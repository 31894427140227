// @flow
import React from 'react';
import { Image } from 'react-native';
import md5 from 'md5';

type Props = {
  email: string,
  size?: number,
  style?: Object,
}

const Avatar = ({ email, size = 40, style }: Props) => {

	if( typeof email === 'string' ) {

		const hash = md5(email);
		const uri = `https://secure.gravatar.com/avatar/${hash}?d=identicon`;

		return (
			<Image
				source={{ uri }}
				style={{ width: size, height: size, borderRadius: size / 2, ...style }}
			/>
		);
	}

	return (<Image />);
};

export default Avatar;
