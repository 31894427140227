import { AppRegistry }    from "react-native";
import { configureAxios } from 'config/axios';
import App           from "./App";
import LocationAlert from "components/DialogLocation";
import store     from './store.js';
import * as GEO  from 'utils/geocode.js';
import * as SESS from 'actions/session';
import * as serviceWorkerReg  from './serviceWorkerReg';
import { uploadOfflineData2 } from 'actions/pending';
import { isOnline }  from 'utils';
import LS            from 'utils/localStore.js';
import OS            from 'utils/offlineStore.js';

AppRegistry.registerComponent("App", () => App);
AppRegistry.registerComponent("LocationAlert", () => LocationAlert);

configureAxios(store);
serviceWorkerReg.register();

localStorage.setItem('isUploading', 'false');
localStorage.setItem('isInspectionsLoaded', 'false');

const syncOfflineData = () => {
	// Upload offline data when application in online
	setInterval(() => {
		if(!['/', '/login', '/forgotPassword'].includes(window.location.pathname)) {
			LS.Get('auth', 1)
			.then((authRet) => {
				if(authRet) {
					OS.All('pendingAPI')
					.then((requests) => {
						if(isOnline() && requests.length > 0 && localStorage.getItem('isUploading') === 'false') {
							localStorage.setItem('isUploading', 'true');
							uploadOfflineData2();
						}
					});
				}
			})
			.catch(err => Promise.reject(err));
		}
	}, 5000);
}

syncOfflineData();

SESS.fetchConstants(true)
	.catch((err) => {
		alert('Failed to load app constants');
	})
	.then((oCONST) => {

		return GEO.getPosition()
			.then((oPOS) => {

				// reload/renew JWT after constants have been loaded
				return SESS.authRefresh(store)
					.catch((err) => {
						console.error({ authRefresh: err });
					})
					.finally(() => {

						return AppRegistry.runApplication("App", {
							rootTag: document.getElementById("root")
						});
					});
			})
			.catch((err) => {
				// alert(`To assist in the appraisal process, WAIVIT requires GPS access.  Please enable geolocation for this website, then try again.\n\n[${err.code}] ${err.message}`);
				return AppRegistry.runApplication("LocationAlert", {
					rootTag: document.getElementById("root")
				});
			});
	});

