// @flow
import React from 'react';
import styled from 'styled-components/native';

const Component = styled.View`
	padding: 2em;
  align-items:     center;
  justify-content: center;
`;

const Label = styled.Text`
  margin-bottom: 10px;
  color:         rgb(150,160,170);
  font-weight:   bold;
`;

const Message = styled.Text`
  color: rgb(150,160,170);
  text-align: center;
`;

type Props = {
  label?: string,
  children?: any,
};

const NoResults = ({ label, children }: Props) =>
  <Component pointerEvents="none">
    {label &&
      <Label>
        {label}
      </Label>
    }
    <Message>
      {children}
    </Message>
  </Component>;

export default NoResults;
