// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { View, Text, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import snip   from 'styles/snip.js';
import { logout }    from 'actions/session';

const events = ['click', 'mouseover','mousedown','scroll','keypress','load'];

const Shadow = styled(View)`
	z-index:  200;
	position: fixed;
	top:      0px;
	bottom:   0px;
	width:    100%;
	padding:  1rem;

	display:  flex;
	flex-direction:  column;
	justify-content: center;
	align-items:     stretch;

	margin:           0px;
	background-color: rgba(0, 0, 0, 0.5);
`;

const AlertView = styled(View)`
	flex-direction:  column;
	justify-content: space-between;
	text-align: center;
	border:           ${props => snip.bdr};
	border-radius:    10px;
	background-color: white;
	width: fit-content;
	margin: auto;
`;

const Txt = styled(Text)`
	padding:   2rem 1rem;
	font-size: 16px;
`;

const BtnTxt = styled(Text)`
	padding:   0.7rem;
	font-size: inherit;
	color:     rgb(0, 122, 255);
`;

const H2 = styled(Text)`
	padding:   0.7rem;
	font-size: inherit;
	color:     rgb(232, 0, 0);
`;

const Buttons = styled(View)`
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0.5rem;
`;

type Props = {
	logout: Function,
};

type State = {
    warning:   boolean,
    autoClose: boolean,
    remaining: number,
};

class AutoLogout extends Component {
    state: State;
	props: Props;

	constructor(props: Props) {
		super(props);
		this.state = {
            warning:   false,
            autoClose: true,
            remaining: 60
		};
        this.warningTimer = null;
        this.logoutTimer =  null;
        this.timer = null;
	}

    componentDidMount() {
		events.forEach(function(event){
            window.addEventListener(event, this.resetWarningTimer);
         },this);
         this.setTimer();
	}

	setTimer = () => {
        this.warningTimer = setTimeout(this.warningMessage, 1800 * 1000);
        this.setState({ autoClose: true });
    };

    warningMessage = () => {
        this.setState({ warning: true });
        this.logoutTimer = setTimeout(() => { 
            if(this.state.autoClose)
                this.logoutUser(); 
            }, 60 * 1000);
        this.timer = setInterval(() => {
            let remaining = this.state.remaining - 1;
            this.setState({ remaining });
        }, 1000);
    };

    logoutUser = () => {
        events.forEach(function(event){
            window.removeEventListener(event, this.resetWarningTimer);
        },this);
        clearTimeout(this.warningTimer);
        clearTimeout(this.logoutTimer);
        clearTimeout(this.timer);
        this.props.logout();
    };

    resetWarningTimer = () => {
        clearTimeout(this.warningTimer);
        this.setTimer();
    };

    mClose = (evt, continueSession) => {
        this.setState({ autoClose: false, warning: false, remaining: 60 });
        if(continueSession) {
            this.resetWarningTimer();
            clearTimeout(this.logoutTimer);
            clearTimeout(this.timer);
        } else {
            this.logoutUser();
        }
        evt.stopPropagation();
    }

    getRemaining = () => {
        var count = this.state.remaining;
        count = count - 1;
        setTimeout(this.setState({ remaining: count }), 1000);
        return count;
    }


	render() {

		const { warning, remaining } = this.state;

		return (
			<React.Fragment>
				{warning &&
					<Shadow>
						<AlertView>
							<View>
                            <H2 style={{ borderBottom: snip.bdr }}>{'WARNING'}</H2>
								<Txt>
								{`Your session is about to expire!`}{' ('}{remaining}{')'}
								</Txt>
								<Buttons>
                                    <TouchableOpacity
                                        onPress={(e) => this.mClose(e, true)}
                                    >
                                        <BtnTxt>{'Continue'}</BtnTxt>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        onPress={(e) => this.mClose(e, false)}
                                    >
                                        <BtnTxt>{'Exit'}</BtnTxt>
                                    </TouchableOpacity>
                                </Buttons>
							</View>
						</AlertView>
					</Shadow>
				}
			</React.Fragment>
		);
	}
}

export default connect((state) => ({}),
  {
    logout,
  })(AutoLogout);

