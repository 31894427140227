import axios from 'axios';
// import LS    from 'utils/localStore.js';
import OS    from 'utils/offlineStore.js';
import { isOnline } from 'utils';
import {
    INSPECTION_QUESTIONS_SAVED,
} from 'constants.js';


export function saveInspectionQuestions(orgId, inspId, data){
    if(isOnline()){
        return (dispatch) => axios.post(`/organizations/${orgId}/inspections/${inspId}/question_choices`, {question_choice:data, ACTION:"INSERT"})
            .then((rsp) =>{
                dispatch({type:INSPECTION_QUESTIONS_SAVED, rsp})
                return Promise.resolve(rsp);
            })
            .catch((err) => {return Promise.resolve(err)});
    }
    else {
        return (dispatch) => {
            var obj = {
                id: Date.now(),
                api: 'saveInspectionQuestions',
                url: `/organizations/${orgId}/inspections/${inspId}/question_choices`,
                data: {question_choice:data, ACTION:"INSERT"},
            }
            OS.Put('pendingAPI', obj);
            dispatch({type:INSPECTION_QUESTIONS_SAVED, data})
            return Promise.resolve(data);
        }
    }
}