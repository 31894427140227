import React, { Component } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import Avatar from 'components/Avatar';
import { toYMD } from 'utils';
import colors from 'styles/colors';
import * as IO5 from 'react-icons/io5';

const ComponentView = styled.View`
  flex-direction: column;
  background-color: #fff;
  padding: 10px;
  border-top-width: 1px;
  border-color: rgb(220,220,220);
`;

const RowView = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LeftAlign = styled.View`
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

class ImageComment extends Component {
  state = {
    commentModalOpen: false,
    edit: false,
  };

  render() {
    const { comment, pendingInspection, isUploading } = this.props;

    return (
      <ComponentView>
        <LeftAlign>
          <Avatar size={30} email={comment.user.email} style={{ marginRight: 10, borderRadius: 4 }} />
          <View>
            <Text style={{ fontWeight: 'bold', fontSize: 16 }}>
              {comment.user.name}
            </Text>
            <Text style={{ fontSize: 12, color: 'rgb(130,145,160)' }}>
              {toYMD(comment.inserted_at)}
            </Text>
          </View>
        </LeftAlign>
        <Text style={{ fontSize: 16, marginBottom: 10 }}>
          {comment.body}
        </Text>
        { pendingInspection &&
          <RowView>
            <TouchableOpacity onPress={() => this.props.handleEdit(comment)} style={{ flexDirection: 'row', alignItems: 'center' }}>
              <IO5.IoPencil color={colors.green_primary} size={20} />
              <Text style={{ fontSize: 14, marginLeft: 5 }}>Edit</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => this.props.handleDelete(comment)} style={{ flexDirection: 'row', alignItems: 'center',
            cursor: isUploading ? 'not-allowed' : 'pointer',
						pointerEvents: isUploading ? 'none' : 'auto'
           }}>
              <IO5.IoTrashBin color={colors.red} size={20} />
              <Text style={{ fontSize: 14, marginLeft: 5 }}>Delete</Text>
            </TouchableOpacity>
          </RowView>
        }
      </ComponentView>
    );
  }
}

export default ImageComment;
