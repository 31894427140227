// @flow
import React from 'react';
import { View } from 'react-native';
import colors from 'styles/colors.js';

const SceneCommon = (props) => {

	const sDefaults = {
		position:   'relative',
		display:    'inline-block',
		width:      '100%',
		minHeight:  '100vh',
		margin:     '0px',
		background: colors.light_bg,
	};

	const propsNew = {
		...props,
		style: { ...sDefaults, ...props.style },
	};

	return <View {...propsNew} />;
};

SceneCommon.defaultProps = {
  children: undefined,
};

export default SceneCommon;
