import {
	ALERT,
	HIDE_ALERT,
	WAITING,
} from 'constants.js';

const initialState = {
	visible: false,
	title:   '',
	message: '',
	mode:    'ok',
	bWait:   false,
	waitMsg: '',
};

// eslint-disable-next-line
export default function (state = initialState, action) {
	switch (action.type) {
		case ALERT:
			return {
				...state,
				visible: true,
				mode:    action.mode,
				title:   action.title,
				message: action.message,
			};
		case HIDE_ALERT:
			return initialState;
		case WAITING:
			return {
				...state,
				bWait: action.bWait,
				waitMsg: ((action.bWait === true) && (typeof action.waitMsg === 'string')) ? action.waitMsg : '',
			};
		default:
			return state;
	}
}

export const toggleWait = (bWait) => {
	return (dispatch) => {
		dispatch({ type: WAITING, bWait });
	};
};
