// @flow
import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
// import LogoImg     from './EVP_Inhabet_Inspect_Logo_Modified.png';
import LogoImg     from './WAIVIT-Inspect-Lockup-Blue-and-white.png'
import SceneCommon from 'containers/SceneCommon';
import store       from '../../store.js';
import * as SESS   from 'actions/session';

const styleBtn = {
	flexDirection:  'row',
	alignItems:     'center',
	justifyContent: 'center',
	borderRadius:   '3px',
	borderWidth:    '1px',
	borderColor:    'white',
	padding:        '0.75em',
	marginTop:      '5vh',
	width:          '100%',
};

const styleBtnTxt = {
	fontWeight: 'bold',
	color:      '#fff',
	fontSize:   '16px',
};

const styleTextLine = {
	fontSize:      '11pt',
	color:         'white',
	marginTop:     '2em',
	letterSpacing: '2px',
};

const styleTop = {
	display:         'flex',
	flexDirection:   'column',
	justifyContent:  'space-between',
	backgroundColor: 'black',
	padding:         '1rem',
};

export default class Welcome extends React.Component {

	componentDidMount() {
		SESS.authRefresh(store)
		.then((auth) => {
			this.props.history.push('/inspect');
		})
		.catch((err) => {
			console.error({ authRefresh: err });
		})
	}

	render() {

		return (
			<SceneCommon style={styleTop}>
				<View style={{ flex: 1 }}></View>
				<View style={{ flex: 2, justifyContent: 'center', alignItems: 'center' }}>
					<img src={LogoImg} alt="WAIVIT by EVP" style={{ width: '75%', height: 'auto', padding:'14px 0px' }} />
					<Text style={styleTextLine}>PROPERTY INSPECTION TOOLKIT</Text>
					<TouchableOpacity style={styleBtn} onPress={() => this.props.history.push('/login')}>
						<Text style={styleBtnTxt}>LOGIN</Text>
					</TouchableOpacity>
				</View>
				<View style={{ flex: 1 }}></View>
			</SceneCommon>
		);
	}
}
