import axios from 'axios';
import LDSH  from 'lodash';
import LS    from 'utils/localStore.js';
import OS    from 'utils/offlineStore.js';
import store from '../store.js';
import { HIDE_ALERT } from 'constants.js';
import { isOnline }   from 'utils';
import { 
    FETCH_LOAN_SUCCESS,
    FETCH_INSP_WITH_ENTRIES_SUCCESS,
    CHAN_ENTRY_CREATED,
    CHAN_ENTRY_UPDATED,
} from 'constants.js';

export function fetchLoan(orgId, loanId) {
    let oLoan = null;
    if(isOnline()) {
        return (dispatch) =>
            axios.get(`/organizations/${orgId}/loans`, { params: { id: loanId } })
                .then((rsp) => {
                    oLoan = LDSH.get(rsp, 'data[0]', null);
                    dispatch({ type: FETCH_LOAN_SUCCESS, loan: oLoan });
                    axios.get(`/organizations/${orgId}/inspections?page_size=100&loan_id=${oLoan.id}`)
                        .then((resp) => {
                            let arInsp = LDSH.get(resp, 'data', []);
                            dispatch({ type: FETCH_INSP_WITH_ENTRIES_SUCCESS, inspections: arInsp });
                        });
                    return Promise.resolve(oLoan);
                })
                .catch((ex) => {
                    if(ex.error.includes('not found')) {
                        store.dispatch({ type: HIDE_ALERT });
                        return OS.Store('inspections')
                        .filter((V) => (V.loan_id === loanId))
                        .toArray((rsp) => {
                            oLoan = { ...rsp[0].loan, line_items: rsp[0].line_items };
                            dispatch({ type: FETCH_LOAN_SUCCESS, loan: oLoan });
                            dispatch({ type: FETCH_INSP_WITH_ENTRIES_SUCCESS, inspections: rsp });
                            return Promise.resolve(oLoan);
                        });
                    } else {
                        Promise.reject(ex);
                    }
                });
    } else {
        return (dispatch) => {
            return OS.Store('inspections')
            .filter((V) => (V.loan_id === loanId))
            .toArray((rsp) => {
                oLoan = { ...rsp[0].loan, line_items: rsp[0].line_items };
                dispatch({ type: FETCH_LOAN_SUCCESS, loan: oLoan });
                dispatch({ type: FETCH_INSP_WITH_ENTRIES_SUCCESS, inspections: rsp });
                return Promise.resolve(oLoan);
            });
        }
    }
}

export function createEntry(data) {
    if(isOnline()) {
        return (dispatch) => axios.post(`/disb_entry`, data)
            .then((rsp) => {
                dispatch({ type: CHAN_ENTRY_CREATED, entry: rsp.data });
                return Promise.resolve(rsp);
            })
            .catch((err) => {return Promise.resolve(err)});
    } else {
        return (dispatch) => {
            var obj = {
                id: Date.now(),
                api: 'createEntry',
                data: data,
            }
            const entry = { ...data, id: 'Temp-' + obj.id };
            OS.Put('pendingAPI', obj);
            OS.Get('inspections', data.inspection_id)
		    .then((insp) => {
                if( Array.isArray(insp.entries) )
                    insp.entries.push(entry);
				else
                    insp.entries = [entry];

                LS.Put('inspections', { ...insp });
                OS.Put('inspections', { ...insp });
            })

            dispatch({ type: CHAN_ENTRY_CREATED, entry: entry });
            return Promise.resolve(data);
        }
    }
}

export function updateEntry(data) {
    if(isOnline()) {
        return (dispatch) => axios.patch(`/disb_entry/${data.id}`, data)
            .then((rsp) => {
                dispatch({ type: CHAN_ENTRY_UPDATED, entry: rsp.data });
                return Promise.resolve(rsp);
            })
            .catch((err) => {return Promise.resolve(err)});
    } else {
        return (dispatch) => {
            var obj = {
                id: Date.now(),
                api: 'updateEntry',
                data: data,
            }
            OS.Put('pendingAPI', obj);
            OS.Get('inspections', data.inspection_id)
		    .then((insp) => {
                var entry_index = insp.entries.findIndex(e => e.id === data.id)
				insp.entries[entry_index].allocation = data.allocation;

                LS.Put('inspections', { ...insp });
                OS.Put('inspections', { ...insp });
            })

            dispatch({ type: CHAN_ENTRY_UPDATED, entry: { ...data } });
            return Promise.resolve(data);
        }
    }
}
